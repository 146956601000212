import { FormOutput, QuestionOutput, SectionOutput } from "@addventa/sesha-forms-api";
import { PlusOutlined } from "@ant-design/icons";
import { uuid4 } from "@sentry/utils";
import { Button } from "antd";
import { useEffect, useState } from "react";
import { EditionRule } from "../../../../types/SeshaForm/SeshaRule";
import { Contents, Edition, Identified } from "../../../../types/misc/Generic";
import { addTempId } from "../../../form/create/utils";
import SectionRule from "./SectionRule";

function SectionRules(props: {
  form: Edition<Identified<Contents<FormOutput>>>;
  section: Identified<Contents<SectionOutput>>;
  setForm: React.Dispatch<
    React.SetStateAction<Edition<Identified<Contents<FormOutput>>> | undefined>
  >;
  setIsModified?: React.Dispatch<React.SetStateAction<boolean>>;
  closeModal: React.Dispatch<React.SetStateAction<boolean>>;
  isReviewMode: boolean | undefined;
}) {
  const [possibleQuestions, setPossibleQuestions] = useState<
    Identified<Contents<QuestionOutput>>[]
  >([]);
  const [rules, setRules] = useState<Identified<Contents<EditionRule>>[]>([]);
  /*
  const replaceRulesInfo = (
    rules: Identified<Contents<EditionRule>>[],
    formOut: Edition<Identified<Contents<FormOutput>>>
  ) => {
    const replacedRules: Identified<Contents<EditionRule>>[] = [];
    rules.forEach((rule) => {
      
      const sectionTempId: string = props.isReviewMode
        ? formOut.sections!.find(
            (section: Identified<Contents<SectionOutput>>) => section.tempId === rule.sectionTempId
          )?.tempId!
        : rule.sectionTempId!;

      let newConditions: Identified<Contents<RuleCondition[]>> = [];

      rule.conditions.forEach( (condition) => {
        //id of the Section where the questionId is
        const sectionIndex: number = formOut.sections?.findIndex((section: any) =>
          section.questions!.find(
            (question: any) => rule.conditions.find( condition => question.tempId === condition.questionTempId)
          )
        )!;

        const questionIndex: number = formOut.sections![sectionIndex!].questions!.findIndex(
          (question: Identified<Contents<QuestionOutput>>) =>
            rule.conditions.find( condition => question.tempId === condition.questionTempId)
        )!;

        const questionId = formOut.sections![sectionIndex!].questions![questionIndex].tempId;

        const value: string | undefined = formOut.sections![sectionIndex!].questions![questionIndex!].options!.find(
          (option: Identified<Contents<OptionOutput>>) =>
            rule.conditions.find( condition => option.tempId === condition.valueTempId)
        )?.tempId;

        newConditions.push(addTempId({
          questionId, value
        }))
      });
      
      replacedRules.push(addTempId({
        sectionTempId, 
        conditions: newConditions,
      }));
    });
    
    return replacedRules;
  };
*/

  useEffect(() => {
    var questionsArray: Identified<Contents<QuestionOutput>>[] = [];
    for (const section of props.form.sections!) {
      if (section.order < props.section.order) {
        for (const question of section.questions!) {
          if (question.type === "OPTION") {
            questionsArray.push(question);
          }
        }
      } else {
        // look for questions already pointed by rules and conditions
        let questions = section.questions!.filter((q) =>
          props.form.rules!.find(
            (r) =>
              r.sectionTempId === props.section.tempId &&
              r.conditions.find((c) => q.tempId === c.questionTempId)
          )
        );
        if (questions.length > 0) {
          questionsArray.push(...questions);
        }
      }
    }
    setPossibleQuestions(questionsArray);

    setRules(props.form.rules!);
  }, [props.form]);

  const addNewRule = () => {
    const newRule = addTempId({
      sectionTempId: props.section.tempId,
      conditions: [],
    });
    setRules([...rules, newRule]);
  };

  const updateForm = () => {
    props.setForm((prevForm) => {
      const updatedForm: Edition<Identified<Contents<FormOutput>>> = { ...prevForm! };
      updatedForm.rules = rules;
      return updatedForm;
    });
    props.setIsModified!(true);
    props.closeModal(true);
  };

  return (
    <div>
      <p>Conditions d’affichage de la section “{props.section.title}” : </p>
      {props.isReviewMode && rules.length === 0 ? (
        <p style={{ textAlign: "center", fontStyle: "italic" }}>
          Aucune règle n'est appliquée sur la section.
        </p>
      ) : null}
      {
        // may be optimized with on filter then map
        rules.filter((rule) => rule.sectionTempId === props.section.tempId).length == 0 ? (
          <p style={{ textAlign: "center", fontStyle: "italic" }}>
            Aucune règle n'est appliquée sur la section.
          </p>
        ) : (
          rules.map((rule) => {
            if (rule.sectionTempId === props.section.tempId) {
              return (
                <div key={uuid4()}>
                  <SectionRule
                    rule={rule}
                    section={props.form.sections!.find((s) => s.tempId === rule.sectionTempId)!}
                    form={props.form}
                    setForm={props.setForm}
                    rules={rules}
                    setRules={setRules}
                    potentialTriggerQuestions={possibleQuestions}
                    isReviewMode={props.isReviewMode}
                  />
                </div>
              );
            } else return undefined;
          })
        )
      }

      {!props.isReviewMode ? (
        <>
          <Button
            shape="circle"
            icon={<PlusOutlined />}
            style={{ marginTop: "20px", marginBottom: "20px" }}
            onClick={addNewRule}
            data-cy="add-rule-from-section"
          />

          <Button
            type="primary"
            onClick={() => updateForm()}
            style={{ position: "absolute", right: "30px", bottom: "15px" }}
          >
            Valider
          </Button>
        </>
      ) : null}
    </div>
  );
}

export default SectionRules;
