import { ContactOutput, FormOutput } from "@addventa/sesha-forms-api";
import { notification } from "antd";
import { JSONResponse } from "../types/HTTP/fetch";
import { Contents } from "../types/misc/Generic";

/***************************************************************/
/* THIS FILE IS DEPRECATED, PLEASE CONSIDER USING OPENAPI INSTEAD
/***************************************************************/

const displayError = async (res: Response): Promise<Response> => {
  if (process.env.REACT_APP_ENV !== "local") return res;
  if (res.status < 200 || res.status >= 300) {
    let clonedRes = res.clone();
    let parsed = await clonedRes.json();
    notification.error({
      message: "Error " + res.status,
      description: res.url + ": " + JSON.stringify(parsed),
    });
  }
  return res;
};

async function get<T>(url: string): Promise<JSONResponse<T>> {
  const token = localStorage.getItem("token") || sessionStorage.getItem("token");
  return displayError(
    await fetch(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  ) as Promise<JSONResponse<T>>;
}

async function post<T>(url: string, data: object): Promise<JSONResponse<T>> {
  const token = localStorage.getItem("token") || sessionStorage.getItem("token");
  return displayError(
    await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    })
  ) as Promise<JSONResponse<T>>;
}

async function getBlob(url: string): Promise<Blob> {
  const token = localStorage.getItem("token") || sessionStorage.getItem("token");
  const response = await fetch(url, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  await displayError(response);

  return response.blob();
}

/******** FORMS ********/

/**
 * @deprecated This method should be moved to the OpenAPI package
 */
export const sendFormMail = async (data: Contents<any>): Promise<JSONResponse<any>> => {
  return await post<any>(process.env.REACT_APP_FORMS_BACKEND + "/mail", data);
};

/**
 * @deprecated This method should be moved to the OpenAPI package
 */
export const sendRecap = async (data: Contents<any>): Promise<JSONResponse<any>> => {
  return await post<any>(process.env.REACT_APP_FORMS_BACKEND + "/mail/sendRecap", data);
};

/**
 * @deprecated This method should be moved to the OpenAPI package
 */
export const sendReminders = async (data: Contents<any>): Promise<JSONResponse<any>> => {
  return await post<any>(process.env.REACT_APP_FORMS_BACKEND + "/sendreminders", data);
};

/**
 * @deprecated This method should be moved to the OpenAPI package
 */
export const sendToMailToBox = async (
  contactId: string,
  formId: string,
  data: Contents<any>
): Promise<JSONResponse<any>> => {
  return await post<any>(
    process.env.REACT_APP_FORMS_BACKEND + "/mailToBox/" + contactId + "/" + formId,
    data
  );
};

/**
 * @deprecated This method should be moved to the OpenAPI package
 */
export const sendToIDepot = async (
  code: string,
  contactId: string,
  formId: string,
  formAnswersId: string,
  answerId: string,
  data: Contents<any>
): Promise<JSONResponse<any>> => {
  return await post<any>(
    process.env.REACT_APP_FORMS_BACKEND +
      "/iDepot/" +
      code +
      "/" +
      contactId +
      "/" +
      formId +
      "/" +
      formAnswersId +
      "/" +
      answerId,
    data
  );
};

/******** FORMANSWERS ********/
/**
 * @deprecated This method should be moved to the OpenAPI package
 */
export async function initFormAnswersOfForm(
  formId: string,
  data: Contents<any>
): Promise<JSONResponse<any>> {
  return await post<any>(
    process.env.REACT_APP_FORMS_BACKEND + "/form/" + formId + "/formAnswers/init",
    data
  );
}

export async function downloadFormAnswersCsv(data: {
  form: FormOutput;
  contacts?: ContactOutput[];
}): Promise<JSONResponse<any>> {
  return await post<any>(process.env.REACT_APP_FORMS_BACKEND + "/form/formAnswersToCsv", data);
}

/******** IDEPOT ********/

/**
 * @deprecated This method should be moved to the OpenAPI package
 */
export const testIDepotConnection = async (): Promise<JSONResponse<any>> => {
  return await get<any>(process.env.REACT_APP_FORMS_BACKEND + "/iDepot/testIDepotConnection");
};
/**
 * @deprecated This method should be moved to the OpenAPI package
 */
export const testCodeValidity = async (data: Contents<any>): Promise<JSONResponse<number>> => {
  return await post<any>(process.env.REACT_APP_FORMS_BACKEND + "/iDepot/testCodeValidity", data);
};

export const getIDepotOnForms = async (formId: string): Promise<JSONResponse<any>> => {
  return await get<any>(process.env.REACT_APP_FORMS_BACKEND + "/public/iDepotOnForms/" + formId);
};

/******** AUTH CONTACT ********/
/**
 * @deprecated This method should be moved to the OpenAPI package
 */
export const checkToken = async (token: string): Promise<JSONResponse<any>> => {
  return await get<any>(process.env.REACT_APP_FORMS_BACKEND + "/auth/checkToken/" + token);
};
/**
 * @deprecated This method should be moved to the OpenAPI package
 */
export const checkOtp = async (token: string, data: Contents<any>): Promise<JSONResponse<any>> => {
  return await post<any>(process.env.REACT_APP_FORMS_BACKEND + "/auth/checkOtp/" + token, data);
};
/**
 * @deprecated This method should be moved to the OpenAPI package
 */
export const sendOtp = async (token: string): Promise<JSONResponse<any>> => {
  return await get<any>(process.env.REACT_APP_FORMS_BACKEND + "/auth/sendOtp/" + token);
};
/**
 * @deprecated This method should be moved to the OpenAPI package
 */
export const getFirmLogo = async (firmId: string): Promise<Blob> => {
  return await getBlob(process.env.REACT_APP_FORMS_BACKEND + "/public/firmLogo/" + firmId);
};
