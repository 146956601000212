import { LockOutlined } from "@ant-design/icons";
import type { CountdownProps } from "antd";
import { Alert, Button, Card, Col, Form, Input, Row, Space, Spin, Statistic } from "antd";
import { jwtDecode } from "jwt-decode";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { checkOtp, checkToken, sendOtp } from "../../api/API";
import CyberSec from "../../assets/images/cyber-security.png";
import { useIsTokenSessionValid } from "../../hooks/useIsTokenValid";
import { useIsUserTokenSession } from "../../hooks/useIsUserToken";
import { useNotification } from "../../hooks/useNotification";
import LaunchFormAnswers from "../formAnswers/LaunchFormAnswers";

const { Countdown } = Statistic;

export type Params = {
  token: string;
};

function Auth(props: {
  setDisplayBtnDisco: React.Dispatch<React.SetStateAction<boolean>>;
  setDisconnected: React.Dispatch<React.SetStateAction<boolean>>;
  disconnected: boolean;
  setLogoFirmId: React.Dispatch<React.SetStateAction<string>>;
}) {
  const [form] = Form.useForm();
  const { showNotification } = useNotification();
  const [isTimeout, setIsTimeout] = useState<boolean>(false);
  const [timeoutDuration, setTimeoutDuration] = useState<number>();
  const [otpSuccess, setOtpSuccess] = useState<boolean>(false);
  const [tokenParamOk, setTokenParamOk] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [formAnswerId, setFormAnswerId] = useState<string | undefined>();
  const [isTokenValid, setIsTokenValid] = useState<boolean>(false);
  const isTokenNotExpire = useIsTokenSessionValid();
  const isUserToken = useIsUserTokenSession();

  const { token } = useParams<keyof Params>();

  useEffect(() => {
    if (isUserToken) sessionStorage.removeItem("token");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (token && tokenParamOk) {
      const { accountingFirmId } = jwtDecode<{ accountingFirmId: string }>(token);
      props.setLogoFirmId(accountingFirmId);
    }
  }, [token, tokenParamOk, props]);

  useEffect(() => {
    if (isTokenNotExpire && token) {
      try {
        const { formAnswersId } = jwtDecode<{ formAnswersId: string }>(token);
        const localToken = jwtDecode<{ formAnswersId: string }>(sessionStorage.getItem("token")!);
        if (!localToken.formAnswersId || localToken.formAnswersId !== formAnswersId) {
          setIsTokenValid(false);
          setIsLoading(false);
          sessionStorage.removeItem("token");
        } else {
          setIsTokenValid(true);
        }
      } catch (e) {
        setIsTokenValid(false);
        setIsLoading(false);
      }
    } else {
      setIsTokenValid(false);
    }
  }, [token, isTokenNotExpire, isTokenValid]);

  useEffect(() => {
    (async () => {
      if (token && (!isTokenNotExpire || isUserToken)) {
        const resToken = await checkToken(token);
        if (resToken.status === 200) {
          setTokenParamOk(true);
        } else {
          const data = await resToken.json();
          if (data.timeout !== undefined) {
            setTimeoutDuration(Date.now() + data.timeout);
            setIsTimeout(true);
            setTokenParamOk(true);
          } else {
            setIsLoading(false);
            setTokenParamOk(false);
          }
        }
      }
    })();
  }, [token, isTokenNotExpire, isUserToken, props]);

  useEffect(() => {
    if (token && !isUserToken && (otpSuccess || isTokenValid)) {
      const { formAnswersId } = jwtDecode<{ formAnswersId: string }>(token);
      setFormAnswerId(formAnswersId);
      setTokenParamOk(true);
      setIsLoading(false);
    }
  }, [token, otpSuccess, isTokenValid, isUserToken, props]);

  const onFinish = (values: any) => {
    if (token !== undefined && values !== undefined) {
      (async () => {
        const result = await checkOtp(token, { otp: values.otp });
        if (result.status === 200) {
          const data = await result.json();
          sessionStorage.setItem("token", data.token);
          setOtpSuccess(true);
          props.setDisconnected(false);
          props.setDisplayBtnDisco(true);
        } else {
          showNotification("error", "Code erroné");
          const data = await result.json();
          if (data.timeout !== undefined) {
            setTimeoutDuration(Date.now() + data.timeout);
            setIsTimeout(true);
          }
        }
      })();
    }
  };

  const onFinishTM: CountdownProps["onFinish"] = () => {
    console.log("finished!");
    setIsTimeout(false);
  };

  const onFinishFailed = (errorInfo: any) => {
    //console.log("Failed:", errorInfo);
    //showNotification("error", "Code erroné");
  };

  const sendMailOTP = () => {
    if (token !== undefined) {
      (async () => {
        const resToken = await sendOtp(token);
        if (resToken.status === 200)
          showNotification("success", "Code envoyé par email avec succès");
        else {
          const data = await resToken.json();
          if (data.timeout !== undefined) {
            setTimeoutDuration(Date.now() + data.timeout);
            setIsTimeout(true);
            setTokenParamOk(true);
          }
        }
      })();
    }
  };

  type FieldType = {
    otp?: string;
  };

  return tokenParamOk ? (
    (isTokenValid || otpSuccess) && formAnswerId !== undefined && !isUserToken ? (
      <LaunchFormAnswers
        id={formAnswerId}
        disconnected={props.disconnected}
        setDisplayBtnDisco={props.setDisplayBtnDisco}
      />
    ) : (
      <Col
        className="auth-form"
        style={{
          justifyContent: "center",
          display: "flex",
          alignItems: "center",
          paddingBottom: "10%",
          paddingTop: "10%",
          marginBottom: "30px",
          position: "relative",
          padding: "40px",
        }}
      >
        <Card
          style={{
            position: "relative",
            minHeight: "60%",
            boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
          }}
          //size="small"
          bordered={false}
        >
          <div
            id="auth-form-lock-image"
            style={{
              backgroundImage: `url(${CyberSec})`,
            }}
          ></div>
          {!isTimeout ? (
            <>
              <Row justify="center">
                <p
                  style={{
                    paddingTop: "15px",
                    paddingBottom: "10px",
                    textAlign: "justify",
                    //fontSize: "initial",
                  }}
                >
                  Pour des raisons de sécurité, nous avons envoyé un <b>code à usage unique</b> sur
                  votre adresse email.
                  <br />
                  Veuillez renseigner ce code afin d'accéder à votre formulaire :
                </p>
              </Row>
              <Row justify="center">
                <Form
                  form={form}
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  autoComplete="off"
                  //layout="inline"
                >
                  <Form.Item<FieldType>
                    name="otp"
                    rules={[
                      { required: true, message: "Merci de renseigner le code à usage unique" },
                    ]}
                  >
                    <Input
                      data-cy="otp"
                      style={{ minWidth: "200px", width: "50vw", maxWidth: "300px" }}
                      prefix={<LockOutlined className="site-form-item-icon" />}
                    />
                  </Form.Item>
                  <Form.Item style={{ textAlign: "center" }}>
                    <Button type="primary" htmlType="submit">
                      Accéder au formulaire
                    </Button>
                  </Form.Item>
                </Form>
              </Row>
              <Row>
                <p style={{ fontStyle: "italic", fontSize: "small" }}>
                  Vous n'avez pas reçu votre code ?
                  <Button
                    data-cy="generate code formAnswer"
                    type="link"
                    style={{ padding: "0px 3px 0px 3px", fontStyle: "italic", fontSize: "small" }}
                    onClick={(e) => {
                      e.preventDefault();
                      sendMailOTP();
                    }}
                  >
                    Cliquez ici
                  </Button>
                  pour le re-générer.
                </p>
              </Row>
            </>
          ) : (
            <>
              <Row justify="center">
                <p
                  style={{
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    textAlign: "justify",
                    //fontSize: "initial",
                  }}
                >
                  En raison d'un trop grand nombre d'échec de vérification du code unique, le
                  formulaire est temporairement inaccessible.
                </p>
              </Row>
              <Row justify="center">
                <Countdown title="Temps restant:" value={timeoutDuration} onFinish={onFinishTM} />
              </Row>
            </>
          )}
        </Card>
      </Col>
    )
  ) : (
    <div>
      {isLoading ? (
        <Row justify="center">
          <Spin spinning={isLoading} tip="Loading...">
            <div
              style={{
                padding: "50px",
                background: "rgba(0, 0, 0, 0.05)",
                borderRadius: "4px",
              }}
            />
          </Spin>
        </Row>
      ) : (
        <Row>
          <Col span={12}>
            <Space direction="vertical" style={{ width: "100%", padding: "30px 0 50px 0" }}>
              <Alert
                message={
                  <>
                    <p>Le formulaire que vous essayez de consulter n'est plus accessible.</p>
                    <p>
                      Nous vous invitons à contacter votre cabinet comptable afin d'obtenir plus
                      d'informations.
                    </p>
                  </>
                }
                type="warning"
                showIcon
              />
            </Space>
          </Col>
        </Row>
      )}
    </div>
  );
}

export default Auth;
