import { FormOutput, QuestionOutput, SectionOutput } from "@addventa/sesha-forms-api";
import {
  CheckOutlined,
  CloseOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  MenuOutlined,
  PlusOutlined,
  QuestionCircleFilled,
} from "@ant-design/icons";
import {
  Alert,
  Button,
  Card,
  ConfigProvider,
  Dropdown,
  Input,
  InputRef,
  Modal,
  Tooltip,
  theme,
} from "antd";
import { ItemType } from "rc-menu/lib/interface";
import React, { useEffect, useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { ReactComponent as LogoRules } from "../../../../assets/images/melanger-les-fleches-de-croisement.svg";
import { Contents, Edition, Identified } from "../../../../types/misc/Generic";
import { updateOrderAllQuestions } from "../../../../utils/orderQuestions";
import NewQuestion from "../question/NewQuestion";
import SectionContainer from "./SectionContainer";
import SectionRules from "./SectionRules";

function Section(props: {
  section: Identified<Contents<SectionOutput>>;
  index: number;
  editedSectionId: string;
  setEditedSectionId: React.Dispatch<React.SetStateAction<string>>;
  editedSections: string[];
  setEditedSections: React.Dispatch<React.SetStateAction<string[]>>;
  form: Edition<Identified<Contents<FormOutput>>>;
  setForm: React.Dispatch<
    React.SetStateAction<Edition<Identified<Contents<FormOutput>>> | undefined>
  > | null;
  isReviewMode?: boolean;
  setIsModified?: React.Dispatch<React.SetStateAction<boolean>>;
  setIsEdited?: React.Dispatch<React.SetStateAction<number>>;
}) {
  const {
    token: { colorPrimaryBgHover, colorPrimary },
  } = theme.useToken();

  const [newQuestionInSection, setNewQuestionInSection] = useState<string>();
  const [rulesModal, openRulesModal] = useState<string>("");
  const [deleteSection, setDeleteSection] = useState<string>("");

  const titleAreaTextRef = useRef<InputRef>(null);

  const generateNewSection = (): Identified<Contents<SectionOutput>> => {
    const tempId = uuidv4();
    props.setEditedSectionId(tempId);
    return {
      tempId: tempId,
      title: "Nom de la section...",
      order: props.form!.sections!.length,
      questions: [],
    };
  };

  const addNewSection = (prevSectionIndex: number) => {
    props.setIsModified!(true);
    if (props.setForm) {
      props.setForm((prevForm) => {
        const updatedForm: Edition<Identified<Contents<FormOutput>>> = { ...prevForm! };
        const newSection: Identified<Contents<SectionOutput>> = generateNewSection();
        updatedForm.sections!.splice(prevSectionIndex + 1, 0, newSection);
        updatedForm.sections = updatedForm.sections?.map((section, index) => ({
          ...section,
          order: index + 1,
        }));
        return updatedForm;
      });
    }
  };

  const updateThisSection = (sectionTempId: string, data: any) => {
    props.setIsModified!(true);
    if (props.setForm) {
      props.setForm((prevForm) => {
        const updatedForm: Edition<Identified<Contents<FormOutput>>> = { ...prevForm! };
        const sectionIndex = updatedForm.sections?.findIndex(
          (section: any) => section.tempId === sectionTempId
        );
        if (sectionIndex !== undefined && sectionIndex !== -1 && updatedForm.sections) {
          const section: Identified<Contents<SectionOutput>> = updatedForm.sections[sectionIndex];
          section.title = data.title;
          updatedForm.sections[sectionIndex] = section;
        }
        return updatedForm;
      });
    }
  };

  const removeThisSection = (sectionTempId: string) => {
    props.setIsModified!(true);
    if (props.setForm) {
      props.setForm((prevForm) => {
        var updatedForm: Edition<Identified<Contents<FormOutput>>> = { ...prevForm! };

        let deletedQuestionIds: string[] = updatedForm
          .sections!.find((sec) => sec.tempId === sectionTempId)!
          .questions!.map((q) => q.tempId);

        updatedForm.sections = updatedForm.sections?.filter(
          (sec: Identified<Contents<SectionOutput>>) => sec.tempId !== sectionTempId
        );
        updatedForm = updateOrderAllQuestions(updatedForm);

        updatedForm.rules = updatedForm.rules!.filter(
          (r) =>
            r.sectionTempId !== sectionTempId &&
            !r.conditions.find((c) => deletedQuestionIds.includes(c.questionTempId!))
        );

        updatedForm.sections = updatedForm.sections?.map((section, index) => ({
          ...section,
          order: index + 1,
        }));

        return updatedForm;
      });
      setDeleteSection("");
    }
  };
  const sectionTitleModificationValidation = (
    e: React.ChangeEvent<HTMLInputElement>,
    sectionTempId: string
  ) => {
    updateThisSection(sectionTempId, { title: e.target.value });
    props.setEditedSectionId("");
  };

  const generateAddBtn = (sectionTempId: string) => {
    if (props.isReviewMode) return;
    const items: ItemType[] = [
      {
        key: "1",
        style: {
          height: "50px",
          display: "flex",
          alignItems: "center",
        },
        label: (
          <div style={{ display: "flex", flexDirection: "row", gap: "10px" }}>
            <QuestionCircleFilled style={{ color: colorPrimary }} />
            <span>Ajouter une question</span>
          </div>
        ),
        onClick: () => setNewQuestionInSection(sectionTempId),
      },
      {
        key: "2",
        style: {
          height: "50px",
          display: "flex",
          alignItems: "center",
        },
        label: (
          <div style={{ display: "flex", flexDirection: "row", gap: "10px" }}>
            <MenuOutlined style={{ color: colorPrimary }} />
            <span> Ajouter une section</span>
          </div>
        ),
        onClick: () =>
          addNewSection(
            props.form.sections!.findIndex(
              (elt: Identified<Contents<SectionOutput>>) => elt.tempId === sectionTempId
            )
          ),
      },
    ];
    return (
      <Dropdown menu={{ items }} placement="bottomLeft" trigger={["click"]}>
        <Button
          shape="circle"
          icon={<PlusOutlined />}
          style={{ marginTop: "20px", marginBottom: "20px" }}
        />
      </Dropdown>
    );
  };

  var items: Identified<Contents<QuestionOutput>>[] = props.section.questions!;

  useEffect(() => {
    if (
      titleAreaTextRef &&
      titleAreaTextRef.current &&
      props.editedSectionId === props.section.tempId
    ) {
      titleAreaTextRef.current.focus();
    }
  }, [props.editedSectionId, props.section.tempId]);

  return (
    <div key={props.section.tempId}>
      <ConfigProvider
        theme={{
          components: {
            Modal: {
              headerBg: colorPrimaryBgHover,
            },
          },
        }}
      >
        <div>
          <Card
            style={{
              height: "62px",
              backgroundColor: colorPrimaryBgHover,
              marginBottom: "20px",
              boxShadow: "0px 10px 15px -3px rgba(0,0,0,0.1)",
            }}
            styles={{ body: { padding: "0px 0px 0px 20px" } }}
          >
            <span hidden={props.editedSectionId !== props.section.tempId}>
              <Input
                defaultValue={props.section.title}
                id={props.section.tempId + "-title-textarea"}
                ref={titleAreaTextRef}
                onBlur={(e) => sectionTitleModificationValidation(e, props.section.tempId)}
                style={{
                  fontWeight: "bold",
                  fontSize: "18px",
                  marginTop: "10px",
                  width: "auto",
                }}
              />{" "}
              <Button
                type="text"
                icon={<CheckOutlined />}
                onClick={() => props.setEditedSectionId("")}
              />
            </span>
            {props.editedSectionId !== props.section.tempId && (
              <>
                <h3 style={{ display: "inline-block" }} data-cy="form-creation section-title">
                  {props.section.title}
                </h3>
                {!props.isReviewMode && (
                  <Button
                    type="text"
                    style={{
                      marginLeft: "10px",
                    }}
                    icon={<EditOutlined />}
                    onClick={() => props.setEditedSectionId(props.section.tempId)}
                  />
                )}
              </>
            )}

            {props.form.sections!.indexOf(props.section) !== 0 ? (
              <Tooltip placement="top" title="Conditions d’affichage">
                <Button
                  type="text"
                  icon={<LogoRules />}
                  style={{ display: "inline-block" }}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    openRulesModal(props.section.tempId);
                  }}
                />
              </Tooltip>
            ) : null}
            {props.form.sections!.length > 1 && !props.isReviewMode ? (
              <div style={{ float: "right" }}>
                <Button
                  type="text"
                  icon={<CloseOutlined />}
                  onClick={() => setDeleteSection(props.section.tempId)}
                  style={{ position: "absolute", right: "5px", top: "15px" }}
                />
              </div>
            ) : null}
          </Card>
          <div>
            <SectionContainer
              isReviewMode={props.isReviewMode}
              id={props.section.tempId}
              items={items}
              form={props.form}
              setForm={props.setForm}
              section={props.section}
              setIsModified={props.setIsModified}
              setIsEdited={(editedQuestions: string[]) => {
                // if no edited questions are left in this section, remove it from the edited sections
                if (editedQuestions.length === 0) {
                  props.setEditedSections(
                    props.editedSections.filter((tempId: string) => tempId !== props.section.tempId)
                  );
                } else {
                  if (!props.editedSections.includes(props.section.tempId)) {
                    props.setEditedSections([...props.editedSections, props.section.tempId]);
                  }
                }
              }}
            />
          </div>

          {(newQuestionInSection === props.section.tempId ||
            props.section.questions?.length === 0) &&
            !props.isReviewMode && (
              <NewQuestion
                form={props.form}
                setForm={props.setForm!}
                section={props.section}
                setNewQuestion={setNewQuestionInSection}
                setIsModified={props.setIsModified!}
              />
            )}

          {generateAddBtn(props.section.tempId)}

          <Modal
            open={rulesModal === props.section.tempId}
            title={"Conditions d’affichage"}
            onCancel={() => openRulesModal("")}
            maskClosable={false}
            footer={null}
          >
            <SectionRules
              form={props.form}
              section={props.section}
              setForm={props.setForm!}
              setIsModified={props.setIsModified}
              closeModal={(data) => (data ? openRulesModal("") : null)}
              isReviewMode={props.isReviewMode}
            />
          </Modal>
        </div>{" "}
      </ConfigProvider>
      <ConfigProvider
        theme={{
          components: {
            Button: {
              colorPrimary: "#FF9700",
              colorPrimaryHover: "#FF9700",
            },
          },
        }}
      >
        {" "}
        <Modal
          open={deleteSection === props.section.tempId}
          title={
            <>
              <ExclamationCircleOutlined
                style={{ fontSize: "24px", marginRight: "10px", color: "#FF9700" }}
              />
              Êtes-vous sûr(e) du vouloir supprimer la section ?
            </>
          }
          onOk={() => removeThisSection(props.section.tempId)}
          onCancel={() => setDeleteSection("")}
          maskClosable={false}
          okText="Valider"
          cancelText="Annuler"
        >
          <Alert
            message=""
            description="La suppression de la section entraînera la suppression des questions qu’elle contient. Avant de cliquer sur “valider”, assurez-vous que les questions que vous souhaitez conserver ont été migrées vers une autre section. "
            type="warning"
          />
        </Modal>
      </ConfigProvider>
    </div>
  );
}

export default Section;
