import { ContactOutput, FormOutput } from "@addventa/sesha-forms-api";
import { downloadFormAnswersCsv } from "../api/API";
const XLSX = require("xlsx");

type FormAnswersToCsv = {
  formId: string;
  formTitle: string;
  formDescription: string;
  contactInfo: {
    id: string;
    email: string;
    firstName: string;
    lastName: string;
    accountingFirmId: string;
  };
  sections: {
    title: string;
    order: number;
    answers: {
      questionId: string;
      question: string;
      type: string;
      mandatory: boolean;
      multiple: boolean;
      order: number;
      other: boolean;
      value: any;
      options: any[];
    }[];
  }[];
}[];

const manyFormsAnswersToCsv = async (form: FormOutput, contacts: ContactOutput[] | undefined) => {
  form.createdAt = new Date(form.createdAt);
  if (form.updatedAt) {
    form.updatedAt = new Date(form.updatedAt);
  }
  if (form.closeDate) {
    form.closeDate = new Date(form.closeDate);
  }
  if (form.sentAt) {
    form.sentAt = new Date(form.sentAt);
  }

  const res = (await downloadFormAnswersCsv({ form, contacts })) as Response;
  const data = await res.json();
  const formAnswers = data as FormAnswersToCsv;

  const headers = [""];

  // Génération du header
  form.sections?.forEach((section) => {
    headers.push(section.title);
    section.questions?.forEach((question) => {
      if (question.type !== "OPTION") {
        headers.push("");
      } else {
        headers.push("");
        question.options?.forEach((options) => {
          headers.push("");
        });
        if (question.other) {
          headers.push("");
        }
      }
    });
    headers.pop();
  });

  // Génération des questions
  const formQuestions = ["Contact"];

  form.sections?.forEach((section) => {
    section.questions?.forEach((question) => {
      switch (question.type) {
        case "TEXTAREA":
          formQuestions.push(`${question.order + 1}. ${question.title}`);
          break;
        case "OPTION":
          formQuestions.push(`${question.order + 1}. ${question.title}`);
          for (let i = 0; question.options && i < question.options.length; i++) {
            formQuestions.push(
              `${question.order + 1}. ${question.title} - Option ${i + 1} : ${
                question.options[i].label
              }`
            );
          }
          if (question.other) {
            formQuestions.push(`${question.order + 1}. ${question.title} - Autre`);
          }
          break;
        case "UPLOAD":
          formQuestions.push(`${question.order + 1}. ${question.title}`);
          break;
        default:
          break;
      }
    });
  });

  // Génération des réponses
  const formReponses: string[][] = [];
  formAnswers.forEach((formAnswer) => {
    let answers = [];
    answers.push(`${formAnswer.contactInfo.firstName} ${formAnswer.contactInfo.lastName}`);
    formAnswer.sections.forEach((section) => {
      section.answers.forEach((answer) => {
        switch (answer.type) {
          case "TEXTAREA":
            if (answer.value) answers.push(answer.value.toString());
            break;
          case "OPTION":
            answers.push("");
            if (answer.value) {
              for (let i = 0; answer.options && i < answer.options.length; i++) {
                if (answer.value.includes(answer.options[i]._id)) {
                  answers.push("1");
                } else {
                  answers.push("0");
                }
              }
            }
            if (answer.other) {
              answer.value.forEach((value: string) => {
                if (value.startsWith("autre:")) {
                  answers.push(value.substring(6));
                }
              });
            }
            break;
          case "UPLOAD":
            if (answer.value) answers.push(answer.value.toString());
            break;
          default:
            break;
        }
      });
    });
    formReponses.push(answers);
  });

  const ws = XLSX.utils.aoa_to_sheet([]);
  XLSX.utils.sheet_add_aoa(ws, [formQuestions], { origin: "A2" });
  formReponses.forEach((reponse, index) => {
    XLSX.utils.sheet_add_aoa(ws, [reponse], { origin: `A${index + 3}` });
  });

  const wb = XLSX.utils.book_new();
  XLSX.utils.sheet_add_aoa(ws, [headers], { origin: "A1" });
  XLSX.utils.book_append_sheet(wb, ws, "Form Answers");

  const excel = XLSX.write(wb, { bookType: "xlsx", type: "buffer", compression: true });
  const excelBlob = new Blob([excel], { type: "application/vnd.ms-excel" });

  const blobUrl = URL.createObjectURL(excelBlob);
  const link = document.createElement("a");
  link.href = blobUrl;
  link.download = `${form.title}.xlsx`;
  document.body.appendChild(link);
  link.click();

  // Cleanup
  URL.revokeObjectURL(blobUrl);
  document.body.removeChild(link);
};

export { manyFormsAnswersToCsv };
