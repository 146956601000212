import { UserOutput } from "@addventa/sesha-forms-api";
import * as Sentry from "@sentry/react";
import { Col, ConfigProvider, Layout, Row, theme } from "antd";
import { Content } from "antd/es/layout/layout";
import { jwtDecode } from "jwt-decode";
import { useEffect, useState } from "react";
import { useNavigate, useRoutes } from "react-router-dom";
import { apiUser } from "./api-configuration/Configuration";
import { ReactComponent as LogoSeshaFormsBeta } from "./assets/images/logo-forms-beta.svg";
import Auth from "./components/auth/Auth";
import { default as ContactPage } from "./components/contact/ContactTabs";
import NotFound from "./components/errors/NotFound";
import CreateFormTabs from "./components/form/create/CreateFormTabs";
import ReviewForm from "./components/form/review/ReviewFormTabs";
import Homepage from "./components/homepage/Homepage";
import ClientLayout from "./components/layouts/ClientLayout";
import HomepageLayout from "./components/layouts/HomepageLayout";
import StandardLayout from "./components/layouts/StandardLayout";
import IndexRights from "./components/rights/IndexRights";
import Context from "./context/Context";
import { ELocalStorage } from "./enums/ELocalStorage";
import { NotificationProvider } from "./hooks/useNotification";
import themeDark from "./themes/dark.json";
import themeLight from "./themes/light.json";
import { Token } from "./types/Token/Token";

const { Header } = Layout;

function App() {
  const [user, setUser] = useState<UserOutput | null>(null);
  const [fetchUserError, setFetchUserError] = useState<Boolean>(false);

  const [token, setToken] = useState<Token | null>(null);
  const [themeType, setThemeType] = useState<"light" | "dark">("light");
  const [tab, setTab] = useState<string>("forms");
  const [filteredContactsIds, setFilteredContactsIds] = useState<string[]>([]);
  const [reloadSearch, setReloadSearch] = useState<boolean>(false);

  const [disconnected, setDisconnected] = useState<boolean>(false);
  const [displayBtnDisco, setDisplayBtnDisco] = useState<boolean>(false);
  const [logoFirmId, setLogoFirmId] = useState<string>("");

  const navigate = useNavigate();

  const contextValues = {
    user,
    updateUser: setUser,
    token,
    theme: themeType,
    setThemeType: setThemeType,
  };

  useEffect(() => {
    const token: string | null = localStorage.getItem("token");
    if (token) setToken(jwtDecode(token));

    const decodedToken: Token | null = token ? jwtDecode<Token>(token) : null;
    if (decodedToken) {
      Sentry.setUser({
        id: decodedToken.id,
        email: decodedToken.email,
        accountingFirmId: decodedToken.accountingFirmId,
      });
      Sentry.setTag("firm", decodedToken.accountingFirmId);
    }
  }, []);

  useEffect(() => {
    if (window.location.pathname.includes("/landing/")) {
      const splits: string[] = window.location.pathname.split("/");
      const token: string = splits.slice(-1)[0];
      localStorage.setItem("token", token);

      const pageBeforeLogout: string | null = localStorage.getItem(
        ELocalStorage.PAGE_BEFORE_LOGOUT
      );
      localStorage.removeItem(ELocalStorage.PAGE_BEFORE_LOGOUT);
      if (pageBeforeLogout) {
        navigate(pageBeforeLogout);
      } else {
        navigate("/");
      }
    }
  }, []);

  const fetchUser = async (userId: string) => {
    for (let attempt = 0; attempt < 2; attempt++) {
      try {
        const resUser = await apiUser.userFindOne(userId);
        if (!resUser._id) throw new Error("Failed to fetch user after retries");
        setUser(resUser);
        setFetchUserError(false);
        return;
      } catch (error) {
        if (attempt === 1) {
          setFetchUserError(true);
        }
      }
    }
  };

  useEffect(() => {
    if (token) {
      fetchUser(token.id);
    }
  }, [token]);

  useEffect(() => {
    // disconnect thing
  }, [disconnected]);

  const homepageRoute = {
    path: "/",
    element: (
      <HomepageLayout
        tab={tab}
        setFilteredContactsIds={setFilteredContactsIds}
        reloadSearch={reloadSearch}
      />
    ),
    children: [
      {
        path: "/",
        element: (
          <Homepage
            setTab={setTab}
            filteredContactsIds={filteredContactsIds}
            reloadSearch={setReloadSearch}
          />
        ),
      },
    ],
  };

  const collabRoutes = {
    path: "/",
    element: <StandardLayout />,
    children: [
      {
        path: "/form",
        children: [
          { path: ":id", element: <ReviewForm /> },
          {
            path: "create",
            element: <CreateFormTabs />,
            children: [{ path: ":id", element: <CreateFormTabs /> }],
          },
        ],
      },
      {
        path: "/contacts",
        element: <ContactPage />,
      },
      {
        path: "/rights",
        element: <IndexRights />,
      },
    ],
  };

  const clientRoutes = {
    path: "/contact",
    element: (
      <ClientLayout
        setDisconnected={setDisconnected}
        setDisplayBtnDisco={setDisplayBtnDisco}
        displayBtnDisco={displayBtnDisco}
        logoFirmId={logoFirmId}
      />
    ),
    children: [
      { path: "*", element: <NotFound /> },
      {
        path: ":token",
        element: (
          <Auth
            disconnected={disconnected}
            setDisconnected={setDisconnected}
            setDisplayBtnDisco={setDisplayBtnDisco}
            setLogoFirmId={setLogoFirmId}
          />
        ),
      },
    ],
  };

  /*const formAnswersRoutes = {
    path: "/formanswers",
    element: (
      <ClientLayout
        setDisconnected={setDisconnected}
        setDisplayBtnDisco={setDisplayBtnDisco}
        displayBtnDisco={true}
        logoFirmId={logoFirmId}
      />
    ),
    children: [
      {
        path: ":id",
        element: (
          <LaunchFormAnswers disconnected={disconnected} setDisplayBtnDisco={setDisplayBtnDisco} />
        ),
      },
    ],
  };*/

  //const routing = useRoutes([homepageRoute, collabRoutes, clientRoutes, formAnswersRoutes]);

  const routing = useRoutes([homepageRoute, collabRoutes, clientRoutes]);

  const {
    token: { colorBgLayout, colorBgContainer, boxShadow },
  } = theme.useToken();

  return (
    <>
      {!fetchUserError ? (
        <Context.Provider value={contextValues}>
          <ConfigProvider
            theme={
              themeType === "dark" ? { ...themeDark, algorithm: theme.darkAlgorithm } : themeLight
            }
          >
            <NotificationProvider>
              <>{routing}</>
            </NotificationProvider>
          </ConfigProvider>
        </Context.Provider>
      ) : (
        <div
          style={{
            background: colorBgLayout,
            minHeight: "100vh",
          }}
        >
          <Layout
            className="layout"
            style={{
              background: colorBgLayout,
              width: "90%",
              maxWidth: "1238px",
              margin: "0 auto",
            }}
          >
            <Header
              style={{
                background: colorBgContainer,
                marginBottom: "30px",
                borderBottomLeftRadius: "20px",
                borderBottomRightRadius: "20px",
                position: "sticky",
                top: 0,
                zIndex: 1000,
                boxShadow: "0px 5px 30px " + boxShadow,
              }}
            >
              <Row>
                <Col span={8}>
                  <div style={{ marginTop: "12px" }}>
                    <LogoSeshaFormsBeta />
                  </div>
                </Col>
              </Row>
            </Header>
            <Content>
              <Row
                justify="center"
                style={{
                  marginTop: "40px",
                  marginBottom: "50px",
                  borderRadius: "20px",
                  background: colorBgContainer,
                }}
              >
                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={24}
                  style={{ paddingTop: "40px", paddingBottom: "30px", textAlign: "center" }}
                >
                  Une erreur est survenue. Merci de rafraîchir la page et, si le problème persiste,
                  vous pouvez contacter le support.
                </Col>
              </Row>
            </Content>
          </Layout>
        </div>
      )}
    </>
  );
}

export default App;
