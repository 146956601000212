import { AccountingFirmOutput, FormOutput, TagOutput } from "@addventa/sesha-forms-api";
import {
  DownloadOutlined,
  EllipsisOutlined,
  EyeOutlined,
  InfoCircleOutlined,
  LockFilled,
} from "@ant-design/icons";
import {
  Button,
  Checkbox,
  ConfigProvider,
  Flex,
  Popover,
  Progress,
  Table,
  Tag,
  Tooltip,
  theme,
} from "antd";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { apiTag } from "../../../../../api-configuration/Configuration";
import { useNotification } from "../../../../../hooks/useNotification";
import { downloadOneFormAnswers } from "../../../../../utils/formAnswersPdf";
import { ELockType, ExtendedSeshaFormsAnswers } from "../../ReviewFormModel.d";
import { YourFormAnswersReminderModal } from "./YourFormAnswersReminderModal";

interface Props {
  form: FormOutput;
  formAnswersList: ExtendedSeshaFormsAnswers[];
  setFormAnswersList: React.Dispatch<React.SetStateAction<ExtendedSeshaFormsAnswers[]>>;
  setModalIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  accountingFirm: AccountingFirmOutput | undefined;
  setModalLocakValiationIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setLockType: React.Dispatch<React.SetStateAction<ELockType>>;
}

export function YourFormAnswersTable(props: Props) {
  const {
    token: {
      colorText,
      colorSuccessText,
      colorPrimaryBg,
      colorPrimaryBorder,
      colorFillSecondary,
      colorFill,
      colorTextSecondary,
      colorPrimaryBgHover,
      colorSuccess,
      colorPrimary,
    },
  } = theme.useToken();

  const [modalMailFormAnswersList, setModalMailFormAnswersList] =
    useState<ExtendedSeshaFormsAnswers[]>();

  const { showNotification } = useNotification();

  const [modalMailReminderIsOpen, setModalMailReminderIsOpen] = useState<boolean>(false);

  const [tags, setTags] = useState<TagOutput[]>([]);
  const [completionRateMessage] = useState<string>(
    props.form.sections!.find((section) =>
      section.questions!.find((question) => question.mandatory)
    )
      ? "Pourcentage calculé à partir des réponses aux questions obligatoires ((Nombre de réponses/nombre de questions) x 100)"
      : "Pourcentage calculé à partir de toutes les réponses ((Nombre de réponses/Nombre de questions) x 100)"
  );

  useEffect(() => {
    (async () => {
      const resTags = await apiTag.tagFindAll();
      setTags(resTags);
    })();
  }, []);

  useEffect(() => {
    setModalMailFormAnswersList(props.formAnswersList.filter((answer) => answer.isSelected));
  }, [props.formAnswersList]);

  const defaultColumns = [
    {
      title: "",
      key: "isSelected",
      render: (_: null, record: ExtendedSeshaFormsAnswers) => (
        <Checkbox
          checked={props.formAnswersList[parseInt(record.key)].isSelected}
          onChange={(e) => {
            props.setFormAnswersList((currentList) => {
              return currentList.map((item) => {
                if (item.key === record.key) {
                  return { ...item, isSelected: e.target.checked };
                }
                return item;
              });
            });
          }}
        />
      ),
    },
    {
      title: "Nom du contact",
      render: (record: ExtendedSeshaFormsAnswers) =>
        `${record.contact.firstName} ${record.contact.lastName}`,
      key: "contactFullname",
      sorter: (a: ExtendedSeshaFormsAnswers, b: ExtendedSeshaFormsAnswers) =>
        a.contact.firstName.localeCompare(b.contact.firstName),
      showSorterTooltip: false,
    },
    {
      key: "tags",
      sorter: (a: ExtendedSeshaFormsAnswers, b: ExtendedSeshaFormsAnswers) => {
        if (a.contact.tags.length === 0 || b.contact.tags.length === 0) return 1;
        return a.contact.tags[0].localeCompare(b.contact.tags[0]);
      },
      title: "Tags",
      render: (record: ExtendedSeshaFormsAnswers) => {
        const sortTags = record.contact.tags?.sort((a, b) =>
          a.localeCompare(b, "fr", { numeric: true })
        );
        return (
          <>
            {record.contact.tags.length > 0 ? (
              <>
                {sortTags?.slice(0, 2).map((tag) => {
                  const contactTag = tags.find((elt) => elt._id === tag);
                  if (contactTag)
                    return (
                      <Tag
                        color={contactTag!.color}
                        key={tag}
                        style={{ color: "#2F2F2F", margin: "2.5px 8px 2.5px 0px" }}
                      >
                        {`${
                          contactTag!.label.length > 14
                            ? contactTag!.label.slice(0, 14) + "..."
                            : contactTag!.label ?? ""
                        }`}
                      </Tag>
                    );
                  return undefined;
                })}
                {sortTags.length > 2 && (
                  <Popover
                    trigger="hover"
                    content={
                      <Flex>
                        {sortTags.slice(2).map((tag) => {
                          const contactTag = tags.find((elt) => elt._id === tag);
                          if (contactTag)
                            return (
                              <Tag
                                color={contactTag!.color}
                                key={tag}
                                style={{ color: "#2F2F2F", margin: "0px 4px 0px 4px" }}
                              >
                                {contactTag!.label ?? ""}
                              </Tag>
                            );
                          return undefined;
                        })}
                      </Flex>
                    }
                  >
                    <Tag
                      icon={<EllipsisOutlined />}
                      color="blue"
                      bordered={false}
                      style={{ marginRight: "0px" }}
                    />
                  </Popover>
                )}
              </>
            ) : (
              <></>
            )}
          </>
        );
      },
      showSorterTooltip: false,
    },
    {
      title: "Premier envoi",
      render: (record: ExtendedSeshaFormsAnswers) => (
        <p
          style={{
            color: colorTextSecondary,
          }}
          data-cy="send-date"
        >
          {dayjs(record.sendDate).format("DD/MM/YYYY")}
        </p>
      ),
      key: "sendDate",
      sorter: (a: ExtendedSeshaFormsAnswers, b: ExtendedSeshaFormsAnswers) => {
        if (a.sendDate && b.sendDate) {
          return a.sendDate.getTime() - b.sendDate.getTime();
        }
        return 0;
      },
      showSorterTooltip: false,
    },
    {
      title: "Dernière relance",
      render: (record: ExtendedSeshaFormsAnswers) => (
        <p
          style={{
            color: colorTextSecondary,
          }}
        >
          {record.lastSendDate ? dayjs(record.lastSendDate).format("DD/MM/YYYY") : ""}
        </p>
      ),
      key: "sendDate",
      sorter: (a: ExtendedSeshaFormsAnswers, b: ExtendedSeshaFormsAnswers) => {
        if (a.lastSendDate && b.lastSendDate) {
          return a.lastSendDate.getTime() - b.lastSendDate.getTime();
        }
        return 0;
      },
      showSorterTooltip: false,
    },
    {
      title: (
        <span>
          Taux de complétion{" "}
          <Tooltip title={completionRateMessage}>
            <InfoCircleOutlined />
          </Tooltip>
        </span>
      ),
      dataIndex: "completionRate",
      key: "completionRate",
      render: (_: null, record: ExtendedSeshaFormsAnswers) => (
        <Flex gap="small" align="center" style={{ position: "relative" }}>
          <Flex
            style={{
              position: "absolute",
              top: 4,
              left:
                record.completionRate?.toString().length === 2
                  ? 49
                  : record.completionRate?.toString().length === 3
                  ? 44
                  : 55,
              zIndex: 1,
            }}
            gap={5}
            align="center"
          >
            <p
              style={{
                color:
                  record.closed && record.completionRate && record.completionRate >= 100
                    ? "#FFF"
                    : colorText,
              }}
            >
              {record.completionRate}%
            </p>
            <LockFilled
              style={{
                color: "#FF9700",
                fontSize: 12,
                display: record.closed ? "" : "none",
                position: "relative",
                top: 1,
              }}
            />
          </Flex>
          <Progress
            percent={record.completionRate}
            size={[160, 25]}
            strokeColor={
              record.closed && record.completionRate && record.completionRate >= 100
                ? colorSuccess
                : "#BCEBEB"
            }
            trailColor={colorFillSecondary}
          />
        </Flex>
      ),
      sorter: (a: ExtendedSeshaFormsAnswers, b: ExtendedSeshaFormsAnswers) =>
        (a.completionRate || 0) - (b.completionRate || 0),
      showSorterTooltip: false,
    },
    {
      title: "",
      dataIndex: "operations",
      width: "5%",
      render: (_: null, record: ExtendedSeshaFormsAnswers) => (
        <Flex gap={"14px"}>
          <Button
            onClick={() => {
              props.setModalIsOpen(true);
              props.setFormAnswersList((previous) => {
                let previousAnswerList = [...previous];
                let previousSelectedAnswer = previousAnswerList.find(
                  (answer) => answer.isLoaded === true
                );
                if (previousSelectedAnswer) previousSelectedAnswer.isLoaded = false;
                previousAnswerList[parseInt(record.key)].isLoaded = true;
                return previousAnswerList;
              });
            }}
            style={{ padding: "3px 7px" }}
            data-cy="preview-button"
          >
            <EyeOutlined style={{ fontSize: 16, position: "relative", top: 1 }} />
          </Button>
          <Button
            loading={record.isLoading}
            onClick={async () => {
              props.setFormAnswersList((previous) => {
                let previousAnswerList = [...previous];
                previousAnswerList[parseInt(record.key)].isLoading = true;
                return previousAnswerList;
              });
              await downloadOneFormAnswers(props.form, record, record.contact);
              props.setFormAnswersList((previous) => {
                let previousAnswerList = [...previous];
                previousAnswerList[parseInt(record.key)].isLoading = false;
                return previousAnswerList;
              });
            }}
            style={{ padding: "3px 7px" }}
          >
            <DownloadOutlined
              style={{
                fontSize: 16,
                display: props.formAnswersList[parseInt(record.key)].isLoading ? "none" : "block",
              }}
            />
          </Button>
        </Flex>
      ),
    },
  ];

  const mailReminder = () => {
    setModalMailReminderIsOpen(true);

    // TODO : Why do that ?
    // This feel like a loss of information, maybe we were not done with the selected items.
    // Futhermore, there is a "deselect all" button if need be.
    //unselectAll();

    //TODO: Should we implement that in YourFormAnswersReminderModal.tsx ? This feel important.
    /*let filteredFormAnswerList = props.formAnswersList.filter((answer) => answer.isSelected);
    filteredFormAnswerList.forEach((answer) => {
      answer.sendDate = new Date();
    });
    await apiFormAnswers.formAnswersUpdateMany(filteredFormAnswerList);*/
  };

  const unselectAll = () => {
    props.setFormAnswersList((previous) => {
      return previous.map((answer) => {
        answer.isSelected = false;
        return answer;
      });
    });
  };

  const atLeastOneIsSelected = props.formAnswersList.some((answer) => answer.isSelected);

  return (
    <ConfigProvider
      theme={{
        components: {
          Table: {
            headerBg: colorPrimaryBgHover,
            headerSortActiveBg: colorPrimaryBg,
            headerFilterHoverBg: colorPrimaryBg,
            headerSortHoverBg: colorPrimaryBg,
          },
        },
      }}
    >
      <Flex vertical gap="small">
        <Flex
          justify="end"
          align="center"
          gap="middle"
          style={{
            backgroundColor: colorPrimaryBgHover,
            padding: "10px 20px",
            borderRadius: 15,
            display: atLeastOneIsSelected ? "flex" : "none",
          }}
        >
          <Button
            type="primary" /*onClick={async () => mailReminder()}*/
            onClick={(e) => {
              e.preventDefault();
              mailReminder();
            }}
          >
            Relancer
          </Button>
          <Button
            type="primary"
            onClick={async () => {
              props.setLockType(ELockType.UNLOCK);
              props.setModalLocakValiationIsOpen(true);
            }}
            data-cy="unlock-button"
          >
            Rétablir l'accès
          </Button>
          <Button
            type="primary"
            style={{
              backgroundColor: colorSuccessText,
            }}
            onClick={async () => {
              props.setLockType(ELockType.LOCK);
              props.setModalLocakValiationIsOpen(true);
            }}
            data-cy="lock-button"
          >
            Valider et clôturer le(s) formulaire(s)
          </Button>
        </Flex>
        <ConfigProvider
          theme={{
            components: {
              Button: {
                colorLink: colorPrimary,
                colorLinkHover: colorPrimaryBorder,
                colorLinkActive: colorPrimaryBorder,
                paddingInline: 5,
              },
            },
          }}
        >
          <Flex align="center">
            <Button
              data-cy="select-all-button"
              type="link"
              onClick={() =>
                props.setFormAnswersList((previous) => {
                  return previous.map((answer) => {
                    answer.isSelected = true;
                    return answer;
                  });
                })
              }
            >
              Tout sélectionner
            </Button>
            /
            <Button
              type="link"
              onClick={() => {
                unselectAll();
              }}
            >
              Tout désélectionner
            </Button>
          </Flex>
        </ConfigProvider>
        <Table
          columns={defaultColumns}
          dataSource={props.formAnswersList}
          pagination={{ position: ["bottomCenter"] }}
          className="yourFormAnswersTable"
        />
        <YourFormAnswersReminderModal
          form={props.form}
          formAnswersList={modalMailFormAnswersList!}
          modalIsOpen={modalMailReminderIsOpen}
          setModalIsOpen={setModalMailReminderIsOpen}
          setFormAnswersList={props.setFormAnswersList}
          accountingFirm={props.accountingFirm}
          notGeneralReminder={true}
        />
      </Flex>
    </ConfigProvider>
  );
}
