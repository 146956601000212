import { CheckCircleFilled } from "@ant-design/icons";
import { Button, Flex, theme } from "antd";
import { useNavigate } from "react-router-dom";
import { EContactCreationStatus, ExtendedSeshaContact } from "../../ContactModel";

interface Props {
  setContactCreationStatus: React.Dispatch<React.SetStateAction<EContactCreationStatus>>;
  setTableDataSource: React.Dispatch<React.SetStateAction<ExtendedSeshaContact[]>>;
  setTags: React.Dispatch<React.SetStateAction<any[]>>;
}

const EditableRow = ({ setContactCreationStatus, setTableDataSource, setTags }: Props) => {
  const {
    token: { colorPrimary },
  } = theme.useToken();

  const navigate = useNavigate();

  const handleAddNewContacts = () => {
    setContactCreationStatus(EContactCreationStatus.IDLE);
    const contactTemplate: ExtendedSeshaContact = {
      accountingFirmId: "fake accounting id",
      creatorId: "creator-id",
      createdAt: new Date(),
      key: 0,
      firstName: "",
      lastName: "",
      email: "",
      mailToBox: "",
      isNewlyAdded: true,
      isFirst: true,
    };
    setTableDataSource([contactTemplate]);
    setTags([]);
  };

  return (
    <Flex
      vertical
      align="center"
      gap="middle"
      className="creationContactSuccess"
      style={{ marginBottom: 100, marginTop: 50 }}
    >
      <CheckCircleFilled style={{ color: colorPrimary, fontSize: 83 }} />
      <h1 style={{ margin: 0 }} data-cy="contact-creation-success">
        Contacts ajoutés avec succès
      </h1>
      <Button type="default" onClick={handleAddNewContacts}>
        Importer de nouveaux contacts
      </Button>
      <Button type="primary" onClick={() => navigate("/?tab=contacts")}>
        Retour aux contacts
      </Button>
    </Flex>
  );
};

export default EditableRow;
