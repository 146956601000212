import {
  FormOutput,
  QuestionOutput,
  QuestionOutputTypeEnum,
  SectionOutput,
} from "@addventa/sesha-forms-api";
import { DeleteOutlined, EditOutlined, HolderOutlined } from "@ant-design/icons";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Button, Card, Col, Input, Row, Switch, message, theme } from "antd";
import { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { ReactComponent as CommentIcon } from "../../../../assets/images/commentaire.svg";
import document from "../../../../assets/images/document.png";
import options from "../../../../assets/images/options.png";
import text from "../../../../assets/images/text.png";
import { EditionRule } from "../../../../types/SeshaForm/SeshaRule";
import { Contents, Edition, Identified } from "../../../../types/misc/Generic";
import { updateOrderAllQuestions } from "../../../../utils/orderQuestions";
import { linkify } from "../../formAnswers/question/utils";
import OptionsField from "../question/OptionsField";
import TextareaField from "../question/TextareaField";
import UploadField from "../question/UploadField";

const { TextArea } = Input;

function checkQuestionValidity(questionToCheck: Identified<Contents<QuestionOutput>>): boolean {
  return (
    questionToCheck.type === "TEXTAREA" ||
    questionToCheck.type === "UPLOAD" ||
    (questionToCheck.type === "OPTION" && questionToCheck.options!.every((o) => o.label.length > 0))
  );
}

function checkNoteAvailability(question: Identified<Contents<QuestionOutput>>): boolean {
  return question.note !== undefined;
}

function QuestionGlobal(props: {
  isReviewMode?: boolean;
  form: Edition<Identified<Contents<FormOutput>>>;
  setForm: React.Dispatch<
    React.SetStateAction<Edition<Identified<Contents<FormOutput>>> | undefined>
  > | null;
  section: Identified<Contents<SectionOutput>>;
  question: Identified<Contents<QuestionOutput>>;
  setIsModified?: React.Dispatch<React.SetStateAction<boolean>>;
  setIsEdited?: (b: boolean) => void;
  editedQuestions?: string[];
  // dragOverlay: boolean;
}) {
  const {
    token: { colorPrimary, colorPrimaryBgHover },
  } = theme.useToken();

  const [editedQuestion, setEditedQuestion] = useState<Identified<Contents<QuestionOutput>>>(
    props.question
  );
  const [questionRules, setQuestionRules] = useState<Identified<Contents<EditionRule>>[]>([]);
  const [mode, setMode] = useState<string>(
    props.question.newQuestion ? "edit" : props.isReviewMode ? "review" : "read"
  );
  const [hover, setHover] = useState<boolean>(false);
  const [isGrabbed, setIsGrabbed] = useState<boolean>(false);
  const [cancelEdit, setCancelEdit] = useState<boolean>(false);
  const [changeQuestionType, setChangeQuestionType] = useState<boolean>(false);
  const [addNote, setAddNote] = useState<boolean>(checkNoteAvailability(props.question));

  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id: props.question.tempId,
    disabled: mode === "edit" ? true : false,
  });

  useEffect(() => {
    if (!props.isReviewMode && !changeQuestionType) {
      if (props.question.newQuestion) {
        props.setIsEdited!(true);
      } else {
        props.setIsEdited!(false);
      }
    }

    if (cancelEdit) setCancelEdit(false);
  }, [editedQuestion]);

  useEffect(() => {
    if (!props.isReviewMode) {
      setQuestionRules(
        props.form.rules!.filter((r) =>
          r.conditions.find((c) => c.questionTempId === editedQuestion.tempId)
        )
      );
    }
  }, [props.form, editedQuestion]);

  const onChangeTitle = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditedQuestion({ ...editedQuestion, title: e.target.value });
  };

  const onChangeNote = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setEditedQuestion({ ...editedQuestion, note: event.target.value });
  };

  const changeMode = (mode: string) => {
    setMode(mode);
    if (mode === "edit") {
      props.setIsEdited!(true);
    } else {
      props.setIsEdited!(false);
    }
  };

  const removeThisQuestion = () => {
    props.setIsModified!(true);
    props.setIsEdited!(false);
    if (props.setForm) {
      props.setForm((prevForm) => {
        var updatedForm: Edition<Identified<Contents<FormOutput>>> = { ...prevForm! };

        const sectionIndex = updatedForm.sections?.findIndex(
          (section: any) => section.tempId === props.section.tempId
        );

        if (sectionIndex !== undefined && sectionIndex !== -1 && updatedForm.sections) {
          const section: Identified<Contents<SectionOutput>> = updatedForm.sections[sectionIndex];
          editedQuestion.newQuestion = false;
          section.questions = section.questions?.filter(
            (quest: Identified<Contents<QuestionOutput>>) => quest.tempId !== editedQuestion.tempId
          );
          updatedForm.sections[sectionIndex] = section;
        }
        updatedForm = updateOrderAllQuestions(updatedForm);

        updatedForm.rules = updatedForm.rules!.filter(
          (r) => !r.conditions.find((c) => c.questionTempId === editedQuestion.tempId)
        );

        return updatedForm;
      });
    }
  };

  const updateThisQuestion = () => {
    if (editedQuestion.note === "") editedQuestion.note = undefined;
    setAddNote(checkNoteAvailability(editedQuestion));

    // clearing options if it's finally not an option field
    if (editedQuestion.type !== "OPTION") {
      editedQuestion.options = [];
    }

    if (!checkQuestionValidity(editedQuestion)) {
      message.error('La question "' + editedQuestion.title + '" possède une option sans libellé.');
    } else {
      if (props.setForm) {
        changeMode("read");
        props.setIsModified!(true);
        props.setForm((prevForm) => {
          const updatedForm: Edition<Identified<Contents<FormOutput>>> = { ...prevForm! };

          const sectionIndex = updatedForm.sections?.findIndex(
            (section: any) => section.tempId === props.section.tempId
          );

          if (sectionIndex !== undefined && sectionIndex !== -1 && updatedForm.sections) {
            const section: Identified<Contents<SectionOutput>> = updatedForm.sections[sectionIndex];
            editedQuestion.newQuestion = false;
            section.questions = section.questions?.map((quest: any) =>
              quest.tempId === editedQuestion.tempId ? editedQuestion : quest
            );
            updatedForm.sections[sectionIndex] = section;
          }

          updatedForm.rules = updatedForm
            .rules!.filter(
              (r) => !r.conditions.find((c) => c.questionTempId === editedQuestion.tempId)
            )
            .concat(
              questionRules.filter((rule) =>
                editedQuestion.options!.find((o) =>
                  rule.conditions.find((c) => o.tempId === c.valueTempId)
                )
              )
            );

          return updatedForm;
        });
      }
    }
  };

  const questionType = (question: Identified<Contents<QuestionOutput>>) => {
    switch (question.type) {
      case "TEXTAREA":
        return <TextareaField key={question.tempId} question={question} mode={mode} />;
      case "OPTION":
        return (
          <OptionsField
            form={props.form}
            questionRules={questionRules}
            setQuestionRules={setQuestionRules}
            setQuestion={setEditedQuestion}
            key={question.tempId}
            question={question}
            section={props.section}
            mode={mode}
            cancelEdit={cancelEdit}
          />
        );
      case "UPLOAD":
        return <UploadField key={question.tempId} question={question} mode={mode} />;
      default:
        return <></>;
    }
  };

  const cardStyle =
    mode === "edit"
      ? {
          borderRadius: "20px",
          boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
          marginBottom: "30px",
        }
      : {
          marginBottom: "10px",
          boxShadow: isGrabbed ? "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px" : "none",
          //padding: isGrabbed ? "4%" : "none",
        };
  const style =
    mode !== "edit"
      ? {
          transform: CSS.Transform.toString(transform),
          transition,
          opacity: isGrabbed ? 0.7 : 1,
        }
      : undefined;

  return (
    <li
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      ref={setNodeRef}
      style={style}
    >
      <Card
        style={cardStyle}
        data-cy={`question-card-${props.question.title}`}
        styles={{
          body:
            mode !== "edit" ? { padding: "0px 0px 0px 20px" } : { paddingBottom: 0, border: "Opx" },
        }}
        actions={
          mode === "edit"
            ? [
                <div style={{ paddingRight: "20px" }}>
                  <Button
                    style={{ marginRight: "20px" }}
                    onClick={() => {
                      const currentSection: Identified<Contents<SectionOutput>> | undefined =
                        props.form.sections!.find(
                          (elt: any) => elt.tempId === props.section.tempId
                        );

                      const prevQuestion = currentSection?.questions?.find(
                        (elt: any) => elt.tempId === editedQuestion.tempId
                      );
                      setCancelEdit(true);
                      setChangeQuestionType(false);
                      if (!props.question.newQuestion) {
                        setEditedQuestion(prevQuestion!);
                        changeMode("read");
                      } else removeThisQuestion();
                    }}
                  >
                    Annuler
                  </Button>
                  <Button
                    type="primary"
                    disabled={
                      editedQuestion.type === "OPTION" && editedQuestion.options!.length === 0
                        ? true
                        : false
                    }
                    onClick={() => {
                      updateThisQuestion();
                    }}
                  >
                    {props.question.newQuestion ? "Ajouter" : "Modifier"}
                  </Button>
                </div>,
              ]
            : []
        }
      >
        <div>
          {hover && !["edit", "review"].includes(mode) ? (
            <HolderOutlined
              onMouseEnter={() => setIsGrabbed(true)}
              onMouseLeave={() => setIsGrabbed(false)}
              style={{
                position: "absolute",
                right: "2px",
                left: 0,
                top: "50%",
                fontSize: "15px",
                fontWeight: "bold",
                cursor: isGrabbed ? "grabbing" : "grab",
                color: "grey",
              }}
              {...attributes}
              {...listeners}
            />
          ) : null}
          {mode === "read" ? (
            <div style={{ marginBottom: "30px" }}>
              <span style={{ fontWeight: "bold", verticalAlign: "top" }}>
                {props.section.questions!.indexOf(props.question)! + 1 + "."}{" "}
              </span>
              <span
                style={{
                  display: "inline-block",
                  width: "80%",
                  textAlign: "justify",
                  wordBreak: "break-word",
                }}
              >
                {props.question.title}
                {props.question.mandatory ? (
                  <span style={{ color: colorPrimary, marginLeft: "5px" }}>*</span>
                ) : null}
              </span>{" "}
              {props.editedQuestions?.length === 0 &&
              props.editedQuestions.includes(props.question.tempId) ? null : (
                <div style={{ float: "right" }}>
                  <Button
                    shape="circle"
                    icon={<EditOutlined />}
                    onClick={() => {
                      changeMode("edit");
                    }}
                  />
                  <Button
                    shape="circle"
                    icon={<DeleteOutlined />}
                    onClick={() => removeThisQuestion()}
                    style={{ marginLeft: "10px" }}
                  />
                </div>
              )}
            </div>
          ) : mode === "review" ? (
            <div style={{ marginBottom: "10px" }}>
              <span style={{ fontWeight: "bold" }}>
                {props.section.questions!.indexOf(props.question)! + 1 + "."}{" "}
              </span>
              <span>{props.question.title}</span>{" "}
              {props.question.mandatory ? <span style={{ color: colorPrimary }}>*</span> : null}
            </div>
          ) : (
            <div style={{ marginBottom: "10px" }}>
              <span>
                {"Question n°" + (props.section.questions!.indexOf(props.question)! + 1)}{" "}
              </span>
              {props.question.mandatory ? <span style={{ color: colorPrimary }}>*</span> : null}
              <Input defaultValue={props.question.title} onChange={onChangeTitle} />
            </div>
          )}
        </div>

        {mode === "edit" ? (
          <div>
            {addNote ? (
              <div style={{ marginBottom: "20px" }}>
                <span>Note du cabinet : </span>
                <TextArea
                  defaultValue={props.question.note}
                  onChange={(event) => onChangeNote(event)}
                />
              </div>
            ) : (
              <Button
                type="text"
                style={{
                  color: colorPrimary,
                  fontStyle: "italic",
                  marginBottom: "10px",
                  display: "inline-flex",
                  alignItems: "center",
                }}
                icon={<CommentIcon />}
                onClick={() => setAddNote(true)}
              >
                Ajouter une note
              </Button>
            )}
          </div>
        ) : (
          <>
            {addNote ? (
              <div style={{ marginBottom: "20px", paddingRight: "10px" }}>
                <span>Note du cabinet : </span>
                <span
                  style={{
                    color: colorPrimary,
                    fontStyle: "italic",
                    marginBottom: "10px",
                    wordBreak: "break-word",
                    whiteSpace: "pre-line",
                  }}
                >
                  {linkify(props.question.note)}
                </span>
              </div>
            ) : null}
          </>
        )}

        {mode === "edit" ? (
          <div style={{ marginBottom: "20px" }}>
            <span>Mode de réponse</span>{" "}
            <Button
              onClick={(e) => {
                e.preventDefault();
                setChangeQuestionType(true);
              }}
              style={{ marginLeft: "20px" }}
            >
              Changer le mode de réponse
            </Button>
          </div>
        ) : null}
        {changeQuestionType ? (
          <Row justify="space-between">
            {["TEXTAREA", "OPTION", "UPLOAD"].map((type) => (
              <Col
                xs={24}
                sm={22}
                md={7}
                lg={7}
                id={uuidv4() + "-" + type}
                key={uuidv4() + "-" + type}
              >
                <Button
                  key={type}
                  style={{
                    background: colorPrimaryBgHover,
                    border: 0,
                    fontWeight: 700,
                    height: "80px",
                    width: "170px",
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    setEditedQuestion({ ...editedQuestion, type: type as QuestionOutputTypeEnum });
                    props.setIsEdited!(true);
                    setChangeQuestionType(false);
                  }}
                >
                  {type === "TEXTAREA" ? (
                    <div style={{ textAlign: "center", paddingTop: "5px" }}>
                      <img src={text} alt="textarea" style={{}} />
                      <span style={{ display: "block", fontSize: "12px" }}>Champ libre</span>
                    </div>
                  ) : type === "OPTION" ? (
                    <div style={{ textAlign: "center", paddingTop: "5px" }}>
                      <img src={options} alt="option" style={{}} />
                      <span style={{ display: "block", fontSize: "12px" }}>Case à cocher</span>
                    </div>
                  ) : (
                    <div style={{ textAlign: "center", paddingTop: "5px" }}>
                      <img src={document} alt="upload" style={{}} />
                      <span style={{ display: "block", fontSize: "12px" }}>Pièce jointe</span>
                    </div>
                  )}
                </Button>
              </Col>
            ))}
          </Row>
        ) : (
          <div style={{ marginBottom: "25px" }}>{questionType(editedQuestion)}</div>
        )}

        {mode === "edit" ? (
          <div style={{ marginTop: "10px" }}>
            <span>Rendre obligatoire</span>
            <Switch
              size="small"
              value={editedQuestion.mandatory}
              onChange={() =>
                setEditedQuestion({ ...editedQuestion, mandatory: !editedQuestion.mandatory })
              }
              style={{ marginLeft: "10px" }}
            />
          </div>
        ) : null}
      </Card>
    </li>
  );
}

export default QuestionGlobal;
