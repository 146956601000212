import { Col, Layout, Row, theme } from "antd";
import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import Clouds from "../../assets/images/clouds.png";
import { EFeatures } from "../../enums/EFeatures";
import { ELocalStorage } from "../../enums/ELocalStorage";
import { useIsTokenValid } from "../../hooks/useIsTokenValid";
import { usePermission } from "../../hooks/usePermission";
import ContactFilter from "../homepage/ContactFilter";
import FormSearch from "../homepage/FormSearch";
import Nav from "../nav/Nav";

const { Content } = Layout;

const HomepageLayout = (props: {
  tab: string;
  setFilteredContactsIds: React.Dispatch<React.SetStateAction<string[]>>;
  reloadSearch: boolean;
}) => {
  const isTokenValid = useIsTokenValid();
  const hasAccessToForm = usePermission(EFeatures.ACCESS_TO_FORMS);

  const {
    token: { colorBgLayout, colorBgContainer },
  } = theme.useToken();

  useEffect(() => {
    if (!isTokenValid) {
      localStorage.removeItem("token");
      localStorage.setItem(ELocalStorage.PAGE_BEFORE_LOGOUT, window.location.pathname);
      const url = `${process.env.REACT_APP_URL_SESHA_CLASSIC_FRONT}/logout/forms`;
      window.location.replace(url);
    } else if (!hasAccessToForm) {
      localStorage.removeItem("token");
      window.location.replace(process.env.REACT_APP_URL_SESHA_CLASSIC_FRONT + "/");
    }
  }, [isTokenValid, hasAccessToForm]);

  return (
    isTokenValid &&
    hasAccessToForm && (
      <div
        style={{
          background: colorBgLayout,
          minHeight: "100vh",
        }}
      >
        <Layout
          className="layout"
          style={{
            background: colorBgLayout,
            width: "90%",
            maxWidth: "1238px",
            margin: "0 auto",
          }}
        >
          <Nav />
          <Content>
            <Row
              justify="center"
              style={{
                backgroundImage: `url(${Clouds})`,
                height: "200px",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                borderRadius: "20px",
              }}
            >
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Row justify="center">
                  <Col xs={20} sm={12} md={12} lg={12} xl={12}>
                    {props.tab === "forms" ? (
                      <FormSearch />
                    ) : (
                      <ContactFilter
                        setFilteredContactsIds={props.setFilteredContactsIds}
                        reloadSearch={props.reloadSearch}
                      />
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row
              justify="center"
              style={{
                marginTop: "40px",
                marginBottom: "50px",
                borderRadius: "20px",
                background: colorBgContainer,
              }}
            >
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={24}
                xl={24}
                style={{ paddingTop: "20px", paddingBottom: "30px" }}
              >
                <Outlet />
              </Col>
            </Row>
          </Content>
        </Layout>
      </div>
    )
  );
};

export default HomepageLayout;
