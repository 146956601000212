import {
  ContactOutput,
  FormAnswersOutput,
  FormOutput,
  RuleOutput,
  SectionOutput,
} from "@addventa/sesha-forms-api";
import { Flex } from "antd";
import { useEffect, useState } from "react";
import { apiFormAnswers } from "../../api-configuration/Configuration";
import { sendRecap } from "../../api/API";
import {
  QuestionAndAnswer,
  coupleAllQuestionsAnswers,
  coupleQuestionAnswer,
  filterActiveSections,
} from "../assets/formAnswers/rulesActivation";
import DisplayAllSections from "../assets/formAnswers/section/DisplayAllSections";
import DisplaySection from "../assets/formAnswers/section/DisplaySection";
import { findQuestionSection } from "../assets/formAnswers/utils";
import FormAnswersNavPanel from "./FormAnswersNavPanel";
import FormAnswersValidated from "./FormAnswersValidated";
import FormAnswersValidationPanel from "./FormAnswersValidationPanel";

function getCurrentSection(form: FormOutput, formAnswers: FormAnswersOutput): SectionOutput {
  if (formAnswers.lastAnsweredQuestionId) {
    let [currentSection] = findQuestionSection(form, formAnswers.lastAnsweredQuestionId);
    console.log(currentSection._id);
    return currentSection;
  } else {
    return form.sections![0];
  }
}

function DisplayFormAnswers(props: {
  form: FormOutput;
  contact: ContactOutput;
  formAnswers: FormAnswersOutput;
}) {
  const [inValidationMode, setInValidationMode] = useState<boolean>(false);
  const [validable, setValidable] = useState<boolean>(false);
  const [validated, setValidated] = useState<boolean>(false);

  const [prevSections, setPrevSections] = useState<SectionOutput[]>([]);
  const [currentSection, setCurrentSection] = useState<SectionOutput>(
    getCurrentSection(props.form, props.formAnswers)
  );
  const [nextSections, setNextSections] = useState<SectionOutput[]>([]);

  const [triggeredRule, setTriggeredRule] = useState<boolean>(false);
  const [currentSectionRules, setCurrentSectionRules] = useState<RuleOutput[]>([]);

  const [allQuestionsAnswers] = useState<QuestionAndAnswer[]>(
    coupleAllQuestionsAnswers(props.form.sections!, props.formAnswers.answers)
  );
  const [currentAnswers, setCurrentAnswers] = useState<QuestionAndAnswer[]>(
    currentSection.questions!.map(coupleQuestionAnswer(props.formAnswers.answers))
  );
  const [mandatoryWarning, setMandatoryWarning] = useState<boolean>(false);

  useEffect(() => {
    setCurrentAnswers(
      currentSection.questions!.map(coupleQuestionAnswer(props.formAnswers.answers))
    );
  }, [currentSection, props.formAnswers.answers]);

  // when a rule trigger, re evaluate progress
  useEffect(() => {
    // may be more efficient, with specific identified rule / question / answer trigger event instead of filtering all sections again
    let validSections = filterActiveSections(
      props.form.sections!,
      props.form.rules!,
      allQuestionsAnswers
    );
    setPrevSections(validSections.filter((sct) => sct.order < currentSection.order));
    setNextSections(validSections.filter((sct) => sct.order > currentSection.order));

    // rules triggering
    setCurrentSectionRules(
      props.form.rules!.filter((r) =>
        currentSection.questions!.find((q) =>
          r.conditions.find((condition) => q._id === condition.questionId)
        )
      )
    );
    setTriggeredRule(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    triggeredRule,
    allQuestionsAnswers,
    props.form.sections,
    props.form.rules,
    currentSection.order,
  ]);

  useEffect(() => {
    let scrollableArea: HTMLElement | null = window.document.getElementById(
      "scrollable-answers-validation"
    );
    if (scrollableArea) {
      const bottom =
        Math.abs(
          scrollableArea.scrollHeight - (scrollableArea.scrollTop + scrollableArea.clientHeight)
        ) <= 1;
      if (bottom) setValidable(true);
    }
  }, [inValidationMode]);

  // close formanswers once it was validated
  useEffect(() => {
    (async () => {
      if (validated) {
        props.formAnswers.closed = true;
        let updatedFormAnswers = {
          ...props.formAnswers,
          lastModifiedDate: new Date(),
          closed: true,
        };
        apiFormAnswers.formAnswersUpdateOne(props.formAnswers._id, updatedFormAnswers);

        await apiFormAnswers.formAnswersGenerateOnePDF(props.formAnswers._id, {
          form: props.form,
          formAnswers: props.formAnswers,
          contact: props.contact,
        });

        if (props.form.sendCopyToContacts) {
          sendRecap({
            formId: props.form._id,
            formAnswersId: props.formAnswers._id,
            contactId: props.contact._id,
          });
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validated, props.formAnswers]);

  var content;

  function handleValidationModeScroll(e: React.UIEvent<HTMLElement>) {
    const bottom =
      Math.abs(
        e.currentTarget.scrollHeight - (e.currentTarget.scrollTop + e.currentTarget.clientHeight)
      ) <= 1;
    if (bottom) setValidable(true);
  }

  // for last panel, once every section where answered
  if (inValidationMode) {
    content = (
      <Flex
        vertical
        id="scrollable-answers-validation"
        data-cy="scrollable-answers-validation"
        onScroll={handleValidationModeScroll}
        gap="large"
      >
        <DisplayAllSections
          form={props.form}
          sections={[...prevSections, currentSection]}
          formAnswers={props.formAnswers}
          unansweredQuestionsAreDisplayed={false}
        />
      </Flex>
    );
  }
  // only inform user that it was already validated
  else if (validated) {
    content = (
      <div id="formAnswers-content">
        <FormAnswersValidated form={props.form} />
      </div>
    );
  }
  // displaying each section for the user to answer the form
  else {
    content = (
      <DisplaySection
        form={props.form}
        section={currentSection}
        setNextSections={setNextSections}
        formAnswers={props.formAnswers}
        currentSectionRules={currentSectionRules}
        setTriggeredRule={setTriggeredRule}
        mandatoryWarning={mandatoryWarning}
        isInReviewMode={false}
      />
    );
  }

  return (
    <Flex key={props.form._id} vertical>
      <Flex
        vertical
        style={{
          minHeight: "65vh",
          position: "relative",
        }}
      >
        <Flex key={props.form._id} vertical gap={30}>
          <Flex vertical align="center" justify="center">
            <h1>{props.form.title}</h1>
            <p style={{ display: validated ? "none" : "inline" }}>{props.form.description}</p>
          </Flex>
          {content}
        </Flex>
        {!inValidationMode && !validated && (
          <FormAnswersNavPanel
            formAnswers={props.formAnswers}
            form={props.form}
            inValidationMode={inValidationMode}
            setInValidationMode={setInValidationMode}
            setMandatoryWarning={setMandatoryWarning}
            prevSections={prevSections}
            setPrevSections={setPrevSections}
            currentSection={currentSection}
            setCurrentSection={setCurrentSection}
            nextSections={nextSections}
            setNextSections={setNextSections}
            setCurrentSectionRules={setCurrentSectionRules}
            currentAnswers={currentAnswers}
          />
        )}
      </Flex>
      {inValidationMode && (
        <FormAnswersValidationPanel
          validable={validable}
          setValidable={setValidable}
          setValidated={setValidated}
          setInValidationMode={setInValidationMode}
        />
      )}
    </Flex>
  );
}

export default DisplayFormAnswers;
