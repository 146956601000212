import { FormOutput, OptionOutput, QuestionOutput, SectionOutput } from "@addventa/sesha-forms-api";
import { CloseOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { Badge, Button, Flex, Input, Select, Tag, Tooltip, theme } from "antd";

import { BaseOptionType } from "rc-cascader";
import { CustomTagProps, DisplayValueType } from "rc-select/lib/BaseSelect";
import { FlattenOptionData } from "rc-select/lib/interface";
import React, { ReactNode, useEffect, useState } from "react";
import { ReactComponent as LogoRules } from "../../../../assets/images/melanger-les-fleches-de-croisement.svg";
import { EditionRule } from "../../../../types/SeshaForm/SeshaRule";
import { Contents, Edition, Identified } from "../../../../types/misc/Generic";
import { addTempId } from "../../../form/create/utils";

type SelectOption = { value: Identified<Contents<SectionOutput>>; label: string };

function OneOptionField(props: {
  option: Identified<Contents<OptionOutput>>;
  allOptions: Identified<Contents<OptionOutput>>[];
  setAllOptions: React.Dispatch<React.SetStateAction<Identified<Contents<OptionOutput>>[]>>;
  question: Identified<Contents<QuestionOutput>>;
  questionRules: Identified<Contents<EditionRule>>[];
  setQuestionRules: React.Dispatch<React.SetStateAction<Identified<Contents<EditionRule>>[]>>;
  section: Identified<Contents<SectionOutput>>;
  form: Edition<Identified<Contents<FormOutput>>>;
  other: boolean;
  multiple: boolean;
  mode: string;
  cancelEdit: boolean;
}) {
  const {
    token: { colorPrimary, colorText },
  } = theme.useToken();

  const [label, setLabel] = useState<string>(props.option.label);
  const [possibleSections, setPossibleSection] = useState<Identified<Contents<SectionOutput>>[]>(
    []
  );
  const [currentOptionRules, setCurrentOptionRules] = useState<Identified<Contents<EditionRule>>[]>(
    []
  );
  const [invalidRules, setInvalidRules] = useState<Identified<Contents<EditionRule>>[]>([]);
  const [sectionsToSelect, setSectionsToSelect] = useState<SelectOption[]>([]);
  const [rulesEditor, setRulesEditor] = useState<boolean>(false);
  const [selectedValues, setSelectedValues] = useState<string[]>([]);

  useEffect(() => {
    setLabel(props.option.label);
  }, [props.option]);

  useEffect(() => {
    setPossibleSection(props.form.sections!.filter((s) => s.order > props.section.order));
    setCurrentOptionRules(
      props.form.rules!.filter((rule) =>
        rule.conditions.find((c) => c.valueTempId === props.option.tempId)
      )
    );
  }, [props.form]);

  useEffect(() => {
    if (props.cancelEdit) {
      setPossibleSection(props.form.sections!.filter((s) => s.order > props.section.order));
      setCurrentOptionRules(
        props.form.rules!.filter((rule) =>
          rule.conditions.find((c) => c.valueTempId === props.option.tempId)
        )
      );
    }
  }, [props.cancelEdit]);

  useEffect(() => {
    setSelectedValues(currentOptionRules.map((rule) => rule.sectionTempId!));
    setRulesEditor(currentOptionRules.length > 0);
    setInvalidRules(
      currentOptionRules.filter(
        (rule) => !possibleSections.find((section) => section.tempId === rule.sectionTempId)
      )
    );
  }, [currentOptionRules]);

  useEffect(() => {
    setSectionsToSelect(
      possibleSections
        .concat(
          invalidRules.map(
            (rule) => props.form.sections!.find((s) => s.tempId === rule.sectionTempId)!
          )
        )
        .map((s) => ({
          value: s,
          label: s.title,
        }))
    );
  }, [invalidRules, possibleSections]);

  const handleOptionRule = (id: string) => {
    setRulesEditor(true);
  };
  const handleRemoveOptionRules = (id: string) => {
    setCurrentOptionRules([]);
    props.setQuestionRules(
      props.questionRules.filter(
        (rule) => !rule.conditions.find((c) => c.valueTempId === props.option.tempId)
      )
    );
    setRulesEditor(false);
  };

  const handleNameOption = (e: React.ChangeEvent<HTMLInputElement>, optionId: string) => {
    props.setAllOptions((prevOptions) => {
      return prevOptions.map((opt) => {
        if (opt.tempId === optionId) {
          opt.label = e.target.value;
        }
        return opt;
      });
    });
  };

  const handleRemoveOption = (id: string) => {
    const currentOptions = [...props.allOptions];
    const updatedOptions = currentOptions.filter((option) => option.tempId !== id);

    props.setAllOptions(
      updatedOptions.map((opt, index) => ({ ...opt, value: (index + 1).toString() }))
    );

    setCurrentOptionRules([]);
    props.setQuestionRules(
      props.questionRules.filter(
        (rule) => !rule.conditions.find((c) => c.valueTempId === props.option.tempId)
      )
    );
  };

  const selectOnChange = (values: string[]) => {
    let newOptionsRules: Identified<Contents<EditionRule>>[] = [];
    let newQuestionRules: Identified<Contents<EditionRule>>[] = props.questionRules.filter(
      (r) => !r.conditions.find((c) => c.valueTempId === props.option.tempId)
    );

    values.forEach((tempId) => {
      let existingRules: Identified<Contents<EditionRule>> | undefined = props.form.rules!.find(
        (r) =>
          r.sectionTempId === tempId &&
          r.conditions.find((c) => c.valueTempId === props.option.tempId)
      );
      if (existingRules) {
        newOptionsRules.push(existingRules);
        newQuestionRules.push(existingRules);
      } else {
        let newRule: Identified<Contents<EditionRule>> = addTempId({
          sectionTempId: tempId,
          conditions: [
            addTempId({
              questionTempId: props.question.tempId,
              valueTempId: props.option.tempId,
            }),
          ],
        });
        newOptionsRules.push(newRule);
        newQuestionRules.push(newRule);
      }
    });

    setCurrentOptionRules(newOptionsRules);
    props.setQuestionRules(newQuestionRules);
  };

  function sectionOptionRender(oriOption: FlattenOptionData<BaseOptionType>): ReactNode {
    let notValidOption: boolean =
      possibleSections.find((section) => section.tempId === oriOption.value) === undefined;
    return (
      <Flex>
        {notValidOption && (
          <div style={{ color: "red", marginRight: "4%" }}>
            <ExclamationCircleOutlined />
          </div>
        )}{" "}
        {oriOption.label}{" "}
      </Flex>
    );
  }

  function sectionOptionSelectedRender(selection: CustomTagProps) {
    let notValidOption: boolean =
      possibleSections.find((section) => section.tempId === selection.value) === undefined;
    return (
      <Tag>
        <Flex>
          {notValidOption && (
            <ExclamationCircleOutlined style={{ color: "red", marginRight: "4%" }} />
          )}{" "}
          {selection.label}
        </Flex>
      </Tag>
    );
  }

  function maxTagPlaceHolder(omittedValues: DisplayValueType[]): ReactNode {
    let notValidOption: boolean = omittedValues.some(
      (displayValue) =>
        !possibleSections.find((section) => section.tempId === displayValue.value) === undefined
    );
    return (
      <Badge>
        <Tooltip
          title={omittedValues
            .map((value) => value.label)
            .reduce<React.ReactNode[]>(
              (otherLabels, oneLabel) =>
                otherLabels.length === 0 ? [oneLabel] : otherLabels.concat([<br />, oneLabel]),
              []
            )}
        >
          {omittedValues.length !== currentOptionRules.length ? "+" : ""}
          {omittedValues.length} {omittedValues.length > 1 ? "sections" : "section"}{" "}
          {notValidOption && (
            <ExclamationCircleOutlined style={{ color: "red", marginRight: "4%" }} />
          )}
        </Tooltip>
      </Badge>
    );
  }

  return (
    <Flex
      justify="center"
      align="center"
      style={{
        alignContent: "center",
      }}
    >
      {props.mode === "edit" ? (
        <Input
          style={{ width: "200px", height: "30px" }}
          placeholder="Nom de l'option"
          value={label}
          onBlur={(e) => handleNameOption(e, props.option.tempId)}
          onChange={(e) => setLabel(e.target.value)}
        />
      ) : (
        <>{props.option.label}</>
      )}
      {props.mode === "edit" && !rulesEditor && (
        <Tooltip placement="top" title="définir les règles à appliquer">
          <Button
            type="text"
            icon={<LogoRules />}
            style={{ marginLeft: "5px" }}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              handleOptionRule(props.option.tempId);
            }}
          />
        </Tooltip>
      )}
      {((props.mode === "edit" && rulesEditor) || currentOptionRules.length != 0) && (
        <>
          <i
            style={{
              marginRight: "10px",
              marginLeft: "10px",
              color: "grey",
            }}
          >
            affiche
          </i>

          <Select
            mode="multiple"
            allowClear
            showSearch={true}
            value={selectedValues}
            options={sectionsToSelect.map((o) => ({
              value: o.value.tempId,
              label: <i data-cy={`rules-option`}>{o.label}</i>,
            }))}
            style={{ width: "210px", height: "30px" }}
            onChange={selectOnChange}
            disabled={props.mode !== "edit"}
            maxTagCount={"responsive"}
            maxTagPlaceholder={maxTagPlaceHolder}
            optionRender={sectionOptionRender}
            tagRender={sectionOptionSelectedRender}
          />

          {invalidRules.length > 0 && (
            <div style={{ marginLeft: "2%", color: "red" }}>
              <ExclamationCircleOutlined />
            </div>
          )}
          {props.mode === "edit" && rulesEditor && sectionsToSelect.length > 0 && (
            <Tooltip placement="top" title="supprimer les règles appliquées">
              <Button
                type="text"
                icon={<LogoRules />}
                style={{ marginLeft: "5px" }}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  handleRemoveOptionRules(props.option.tempId);
                }}
              />
            </Tooltip>
          )}
        </>
      )}

      {props.mode === "edit" && (
        <Button
          type="text"
          size="small"
          style={{ marginLeft: "5px" }}
          icon={<CloseOutlined />}
          onClick={() => handleRemoveOption(props.option.tempId)}
        />
      )}
    </Flex>
  );
}

export default OneOptionField;
