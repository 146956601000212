import {
  AccountingFirmOutput,
  FormAnswersWithContactOutput,
  FormInput,
  FormOutput,
  MailNotificationsFrequencyEnum,
  UserFormInput,
  UserFormOutput,
  UserOutput,
} from "@addventa/sesha-forms-api";
import { DeleteOutlined, LockOutlined } from "@ant-design/icons";
import { Button, Checkbox, DatePicker, Flex, Form, Select, TimePicker, Tooltip } from "antd";
import type { CheckboxChangeEvent } from "antd/es/checkbox";
import locale from "antd/es/date-picker/locale/fr_FR";
import dayjs from "dayjs";
import "dayjs/locale/fr";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  apiForm,
  apiFormAnswers,
  apiUser,
  apiUserFormAsso,
} from "../../../api-configuration/Configuration";
import Context from "../../../context/Context";
import { EFeatures } from "../../../enums/EFeatures";
import { useNotification } from "../../../hooks/useNotification";
import { usePermission } from "../../../hooks/usePermission";
import { ELockType } from "../review/ReviewFormModel.d";
import { EMailNotificationFrequency } from "./ReviewFormModel.d";
import SendForm from "./SendForm";

function SendFormParams(props: {
  form: FormOutput;
  accountingFirm: AccountingFirmOutput;
  isFormAnswers?: boolean;
  setModalForLockValidationIsOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  setLockType?: React.Dispatch<React.SetStateAction<ELockType>>;
  setModalDeleteFormIsOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  dayjs.locale("fr");
  const { user } = useContext(Context);
  const { showNotification } = useNotification();
  const navigate = useNavigate();
  const [formAntd] = Form.useForm();
  const canDeleteForm = usePermission(EFeatures.MANAGE_FORMS_RIGHTS);
  const dateZero = new Date(0);

  const [closeDate, setCloseDate] = useState<boolean>(
    props.form.closeDate &&
      props.form.closeDate instanceof Date &&
      props.form.closeDate.getTime() !== dateZero.getTime() &&
      !props.form.isClosed
      ? true
      : false
  );
  const [options, setOptions] = useState<{ value: string; label: string }[]>([]);
  const [openSendForm, setOpenSendForm] = useState<boolean>(false);
  const [form, setForm] = useState<FormOutput>(props.form);
  const [stay, setStay] = useState<boolean>(false);
  const [usersFormAsso, setUsersFormAsso] = useState<UserFormOutput[]>([]);
  const [disableClosing, setDisableClosing] = useState<boolean>(false);
  const [notificationsToSubsAreOn, setNotificationsToSubsAreOn] = useState<boolean>(
    props.form.mailNotifications?.notificationsAreActivated ?? false
  );

  useEffect(() => {
    setForm((prev) => ({ ...props.form, closeDate: prev.closeDate }));
  }, [props.form]);

  useEffect(() => {
    (async () => {
      if (!user) return;
      const resUsers = await apiUser.userFindAll();
      const otherUsers = resUsers.filter((u) => u._id !== user._id);

      setOptions(
        otherUsers.map((u: UserOutput) => ({
          value: u._id,
          label: u.firstName + " " + u.lastName,
        }))
      );

      const resUserFormAsso = await apiUserFormAsso.userFormAssoFindAll();
      setUsersFormAsso(
        resUserFormAsso.filter((u) => u.formId === form._id && u.userId !== user._id)
      );
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const onFinish = (values: any) => {
    var closeDateAndTime;
    if (values.closeFormDate && values.closeFormTime && closeDate) {
      const date = dayjs(values.closeFormDate);
      const time = dayjs(values.closeFormTime);

      closeDateAndTime = date
        .set("hour", time.hour())
        .set("minute", time.minute())
        .set("second", time.second());
    } else {
      closeDateAndTime = undefined;
    }
    setForm({ ...form, closeDate: closeDateAndTime?.toDate() || undefined });

    (async () => {
      try {
        let updatedForm: FormInput = {
          ...form,
          closeDate: closeDateAndTime?.toDate() || new Date(0),
          mailNotifications: {
            frequency: values.notificationsFrequency,
            notifiedUserIds: values.notificationsSubscribers,
            notificationsAreActivated: notificationsToSubsAreOn,
            lastViewedAt: form.mailNotifications?.lastViewedAt ?? new Date(0),
          },
        };
        await apiForm.formUpdateOne(form._id, updatedForm);

        if (values.accessColab.length === 0) {
          await apiUserFormAsso.userFormAssoDeleteMany(usersFormAsso.map((elt) => elt._id));
        } else {
          const accessToAdd: UserFormInput[] = values.accessColab
            .filter((u: string) => !usersFormAsso.map((elt) => elt.userId).includes(u))
            .map((id: string) => ({ formId: form._id, userId: id }));

          const accessToDelete = usersFormAsso
            .filter((u) => !values.accessColab.includes(u.userId))
            .map((elt) => elt._id);

          await apiUserFormAsso.userFormAssoCreateMany(accessToAdd);
          await apiUserFormAsso.userFormAssoDeleteMany(accessToDelete);

          showNotification("success", "Modifications enregistrées");
        }
      } catch (error) {
        console.error("SendFormParams onFinish - " + error);
        showNotification("error", "Erreur lors de l'enregistrement");
      }
    })();
    if (!stay) setOpenSendForm(true);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  const onChange = (e: CheckboxChangeEvent) => {
    setCloseDate(e.target.checked);
  };

  const deleteDraft = () => {
    (async () => {
      await apiForm.formDeleteOne(props.form._id);
      navigate("/");
    })();
  };

  useEffect(() => {
    if (closeDate === false) {
      setForm({ ...form, closeDate: undefined });

      formAntd.setFieldsValue({
        closeFormDate: undefined,
        closeFormTime: undefined,
      });
    }
  }, [closeDate]);

  const closeForAll = () => {
    if (!props.setModalForLockValidationIsOpen || !props.setLockType) return;
    props.setLockType(ELockType.LOCKALL);
    props.setModalForLockValidationIsOpen(true);
  };

  useEffect(() => {
    formAntd.setFieldsValue({
      accessColab: usersFormAsso.map((elt) => elt.userId).filter((u) => u !== user!._id),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formAntd, usersFormAsso, options]);

  useEffect(() => {
    if (props.isFormAnswers)
      (async () => {
        const allFormAnswers: FormAnswersWithContactOutput[] =
          await apiFormAnswers.formAnswersGetFormAnswersByFormId(props.form._id);

        const filteredFormAnswers: FormAnswersWithContactOutput[] =
          await apiFormAnswers.formAnswersGetFormAnswersByFormId(props.form._id, {
            filterByUserId: true,
          });

        if (allFormAnswers.length !== filteredFormAnswers.length) setDisableClosing(true);
      })();
  }, [props.form._id]);

  const selectAll = () => {
    formAntd.setFieldsValue({
      accessColab:
        usersFormAsso.map((elt) => elt.userId).filter((u) => u !== user!._id).length > 0
          ? usersFormAsso.map((elt) => elt.userId).filter((u) => u !== user!._id)
          : options.map((op) => op.value),
    });
  };

  const unselectAll = () => {
    formAntd.setFieldsValue({
      accessColab: [],
    });
  };

  return (
    <>
      {openSendForm && props.form !== undefined ? (
        <SendForm
          form={form}
          setOpenSendForm={setOpenSendForm}
          accountingFirm={props.accountingFirm}
        />
      ) : (
        <Flex vertical align="start">
          <Form
            form={formAntd}
            name="basic"
            style={{ maxWidth: 600 }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            layout="vertical"
            initialValues={{
              closeFormDate:
                form?.closeDate && closeDate && form.closeDate.getTime() !== dateZero.getTime()
                  ? dayjs(form.closeDate)
                  : undefined,
              closeFormTime:
                form?.closeDate && closeDate && form.closeDate.getTime() !== dateZero.getTime()
                  ? dayjs(form.closeDate)
                  : undefined,
            }}
          >
            <Checkbox
              defaultChecked={form?.closeDate && closeDate && !form.isClosed ? true : false}
              onChange={onChange}
              disabled={props.form.isClosed ? true : false}
            >
              Paramétrer une date de clôture du formulaire
            </Checkbox>
            <Form.Item
              label=""
              style={
                closeDate && !props.form.isClosed
                  ? { marginBottom: 0, marginTop: 10 }
                  : { marginBottom: 0, marginTop: 10, color: "#A6A6A6" }
              }
            >
              <div style={{ display: "inline-block", marginTop: "5px", marginRight: "10px" }}>
                Le
              </div>
              <Form.Item style={{ display: "inline-block", width: "200px" }} name="closeFormDate">
                <DatePicker
                  defaultValue={
                    form?.closeDate && form.closeDate.getTime() !== dateZero.getTime()
                      ? dayjs(form.closeDate)
                      : undefined
                  }
                  locale={locale}
                  disabled={!closeDate || props.form.isClosed ? true : false}
                  style={{ width: "100%" }}
                  placeholder=""
                />
              </Form.Item>
              <div
                style={{
                  display: "inline-block",
                  verticalAlign: "middle",
                  marginRight: "10px",
                  marginLeft: "20px",
                }}
              >
                à
              </div>
              <Form.Item style={{ display: "inline-block", width: "200px" }} name="closeFormTime">
                <TimePicker
                  defaultValue={
                    form?.closeDate && form.closeDate.getTime() !== dateZero.getTime()
                      ? dayjs(form.closeDate)
                      : undefined
                  }
                  needConfirm={false}
                  locale={locale}
                  disabled={!closeDate || props.form.isClosed ? true : false}
                  style={{ width: "80px" }}
                  format="HH"
                  placeholder=""
                />
              </Form.Item>
            </Form.Item>
            {props.isFormAnswers ? (
              <Tooltip
                title={
                  disableClosing
                    ? "Il est nécessaire d'avoir accès à l'intégralité des contacts pour clôturer le formulaire"
                    : ""
                }
              >
                <Button
                  icon={<LockOutlined />}
                  type="primary"
                  onClick={() => closeForAll()}
                  disabled={props.form.isClosed || disableClosing ? true : false}
                >
                  Clôturer le formulaire pour tout le monde
                </Button>
              </Tooltip>
            ) : null}
            <hr
              style={{
                backgroundColor: "#F0F0F0",
                border: "0px",
                height: "1px",
                margin: "20px 0px",
              }}
            />
            <Form.Item
              label={
                <>
                  <>Donner l’accès à ce formulaire à d’autres collaborateurs : </>{" "}
                  <Button type="link" onClick={selectAll}>
                    Tout sélectionner
                  </Button>
                  /
                  <Button type="link" onClick={unselectAll}>
                    Tout désélectionner
                  </Button>
                </>
              }
              name="accessColab"
            >
              <Select mode="multiple" options={options} allowClear maxTagCount={"responsive"} />
            </Form.Item>
            <hr
              style={{
                backgroundColor: "#F0F0F0",
                border: "0px",
                height: "1px",
                margin: "20px 0px",
              }}
            />
            <Checkbox
              value={notificationsToSubsAreOn}
              defaultChecked={notificationsToSubsAreOn}
              onChange={() => setNotificationsToSubsAreOn(!notificationsToSubsAreOn)}
              style={{ marginBottom: 10 }}
            >
              Activer les notifications pour ce formulaire
            </Checkbox>
            <Form.Item
              name={"notificationsSubscribers"}
              label={
                <label style={{ marginBottom: 8 }}>
                  Sélectionner les collaborateurs qui seront notifiés :
                </label>
              }
              style={{ marginBottom: 15 }}
            >
              <Select
                mode="multiple"
                options={options}
                defaultValue={() => {
                  const subscribers = props.form.mailNotifications?.notifiedUserIds ?? [];
                  formAntd.setFieldsValue({ notificationsSubscribers: subscribers });
                  return subscribers;
                }}
                allowClear
                maxTagCount={"responsive"}
                disabled={!notificationsToSubsAreOn}
              />
            </Form.Item>
            <Flex vertical={false} gap={15} align="center">
              <p style={{ width: "fit-content", display: "inline-block" }}>Fréquence :</p>
              <Form.Item
                name={"notificationsFrequency"}
                style={{ marginBottom: 0, width: 140, display: "inline-block" }}
              >
                <Select
                  options={Object.values(MailNotificationsFrequencyEnum).map((e) => {
                    return { value: e, label: EMailNotificationFrequency[e] };
                  })}
                  defaultValue={() => {
                    const frequency =
                      props.form.mailNotifications?.frequency ??
                      MailNotificationsFrequencyEnum.Daily;
                    formAntd.setFieldsValue({ notificationsFrequency: frequency });
                    return frequency;
                  }}
                  disabled={!notificationsToSubsAreOn}
                />
              </Form.Item>
            </Flex>
            {!props.isFormAnswers ? (
              <Button icon={<DeleteOutlined />} onClick={() => deleteDraft()}>
                Supprimer le brouillon
              </Button>
            ) : null}
            <Form.Item style={{ marginTop: "20px", textAlign: "right" }}>
              <Button
                htmlType="submit"
                onClick={() => {
                  setStay(true);
                }}
                style={{ marginRight: "10px" }}
              >
                Enregistrer
              </Button>
              {!props.isFormAnswers ? (
                <Button
                  type="primary"
                  htmlType="submit"
                  onClick={() => {
                    setStay(false);
                  }}
                >
                  Suivant
                </Button>
              ) : null}
            </Form.Item>
          </Form>
          {props.isFormAnswers && props.setModalDeleteFormIsOpen && canDeleteForm && (
            <Button
              style={{ marginBottom: "20px" }}
              type="default"
              onClick={() => props.setModalDeleteFormIsOpen!(true)}
              // disabled={props.} // TODO
            >
              Supprimer le formulaire
            </Button>
          )}
        </Flex>
      )}
    </>
  );
}

export default SendFormParams;
