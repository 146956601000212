import { ExclamationCircleOutlined } from "@ant-design/icons";
import { ConfigProvider, Flex, theme } from "antd";

import {
  AnswerOutput,
  FormAnswersOutput,
  FormOutput,
  QuestionOutput,
  RuleOutput,
  SectionOutput,
} from "@addventa/sesha-forms-api";
import DisplayQuestion from "../question/DisplayQuestion";

interface Props {
  form: FormOutput;
  section: SectionOutput;
  setNextSections: React.Dispatch<React.SetStateAction<SectionOutput[]>>;
  formAnswers: FormAnswersOutput;
  currentSectionRules: RuleOutput[];
  setTriggeredRule: React.Dispatch<React.SetStateAction<boolean>>;
  mandatoryWarning: boolean;
  isInReviewMode?: boolean;
}

function DisplaySection(props: Props) {
  const {
    token: { colorPrimaryBgHover, colorText, colorPrimary },
  } = theme.useToken();

  return (
    <ConfigProvider
      theme={{
        components: {
          Input: {
            colorTextDisabled: colorText,
            paddingInline: 20,
          },
          Checkbox: {
            colorTextDisabled: colorText,
          },
          Upload: {
            colorTextDisabled: colorPrimary,
          },
        },
      }}
    >
      <Flex
        vertical
        align="start"
        justify="center"
        style={{ maxWidth: 600 }}
        gap={30}
        data-cy={`section: ${props.section.title}`}
      >
        <h3
          style={{
            backgroundColor: colorPrimaryBgHover,
            padding: 20,
            width: "100%",
            borderRadius: 10,
            boxShadow: "0px 10px 15px -3px rgba(0,0,0,0.1)",
            margin: 0,
          }}
        >
          {props.section.title}
        </h3>
        {props.section.questions!.map((question: QuestionOutput) => (
          <Flex vertical key={question._id} style={{ width: "100%" }}>
            <DisplayQuestion
              form={props.form}
              accountingFirmId={props.form.accountingFirmId}
              section={props.section}
              formAnswers={props.formAnswers}
              question={question}
              answer={
                props.formAnswers.answers.filter(
                  (answer: AnswerOutput) => answer.questionId === question._id
                )[0]!
              }
              currentSectionRules={props.currentSectionRules}
              setTriggeredRule={props.setTriggeredRule}
              mandatoryWarning={props.mandatoryWarning}
              isInReviewMode={props.isInReviewMode}
            />
          </Flex>
        ))}
        {props.mandatoryWarning && (
          <div style={{ color: "red" }} data-cy="formAnswer warning message">
            <ExclamationCircleOutlined /> Veuillez répondre aux questions obligatoires.
          </div>
        )}
      </Flex>
    </ConfigProvider>
  );
}

export default DisplaySection;
